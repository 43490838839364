import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox-mask',
  templateUrl: './checkbox-mask.component.html',
  styleUrls: ['./checkbox-mask.component.scss']
})
export class CheckboxMaskComponent implements OnInit {

  @Input()
  public maskContent:any;

  @Output()
  private maskContentChange:EventEmitter<any>=new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  choose(type){
    var index = this.maskContent.content.indexOf(type);
    this.maskContent.isSelect[index] = !this.maskContent.isSelect[index];
    if(this.maskContent.checked.indexOf(type) == -1){
      this.maskContent.checked.push(type);
    }else{
      var idx = this.maskContent.checked.indexOf(type);
      this.maskContent.checked.splice(idx,1);
    }
    this.maskContentChange.emit(this.maskContent);
  }


  //关闭
  close(){
    this.maskContent.showMask=false;
    this.maskContentChange.emit(this.maskContent);
  }
}
