//服务必引入injectable
import {Injectable} from '@angular/core';

//自定义配置文件
import {AppConfig} from "../confi/app-config";


//obj对象
import { Observable } from 'rxjs';

import { HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
@Injectable()

export class BxdService {
    httpOptions:any;
    
    constructor(private http: HttpClient) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    }

    //调用创建bxd返回编号
    getBxdAdd(data):Observable<any>{
        //return data;
        return this.http.post(AppConfig.baseUrl+"Reimbursement/index",this.getId(data));
    }

    //调用所有bxd类型
    getbxType(data):Observable<any>{
        return this.http.post(AppConfig.baseUrl+"Reimbursement/bxType",this.getId(data));

    }

    //调用添加至明细接口
    getbxdmxAdd(data):Observable<any>{
        return this.http.post(AppConfig.baseUrl+"Reimbursement/bxdmxAdd",this.getId(data));

    }

       //调用报销单类别列表
       getbxdmxType(data):Observable<any>{
        return this.http.post(AppConfig.baseUrl+"Reimbursement/bxdmxType",this.getId(data));

    }

    //调用报销单明细头部数据
    getbxdmxHeadinfo(data):Observable<any>{
      return this.http.post(AppConfig.baseUrl+"Reimbursement/mxTypeInfo",this.getId(data));

    }

     //调用报销单明细更新接口
     getbxdmxEdit(data):Observable<any>{
      return this.http.post(AppConfig.baseUrl+"Reimbursement/bxdmxAdds",this.getId(data));

    }

    //无票报销单更新接口
    getWpEdit(data):Observable<any>{
      return this.http.post(AppConfig.baseUrl+"Tickets/bxdmxAdds",this.getId(data));

    }

    //无票报销单明细接口
     getWbxdmx(data):Observable<any>{
      return this.http.post(AppConfig.baseUrl+"Tickets/bxdmxEdit",this.getId(data));

    }
    //无票报销单上传收据
    getWbxdfile(data):Observable<any>{
      return this.http.post(AppConfig.baseUrl+"Tickets/file",data);

    }

     //调用报销单明细数据接口
     getbxdmx(data):Observable<any>{
      return this.http.post(AppConfig.baseUrl+"Reimbursement/bxdmxEdit",this.getId(data));

    }

    //文件上传功能
    getfile(data):Observable<any>
    {
      return this.http.post(AppConfig.baseUrl+"Reimbursement/file",data);

    }

     //上传后图片显示功能
     getimgInfo(data):Observable<any>
     {
       return this.http.post(AppConfig.baseUrl+"Reimbursement/img",this.getId(data));
 
     }
     //图片删除功能
     getDelimg(data):Observable<any>
     {
       return this.http.post(AppConfig.baseUrl+"Reimbursement/imgDel",this.getId(data));
 
     }

     //调用启动流程接口
     getflow(data):Observable<any>
     {
       return this.http.post(AppConfig.baseUrl+"Flow/flow",this.getId(data));
 
     }


       //调用签名接口
     getqm(data):Observable<any>
    {
      return this.http.post(AppConfig.baseUrl+"Reimbursement/qmfile",this.getId(data));
   
    }


       //调用报销单状态接口
       getbxdS(data):Observable<any>
       {
         return this.http.post(AppConfig.baseUrl+"Reimbursement/bxdStu",this.getId(data));
   
       }

    //删除报销单类别下的数据
    getbxdmxDel(data):Observable<any>
    {
         return this.http.post(AppConfig.baseUrl+"Reimbursement/bxdmxDel",this.getId(data));
   
    }

    //获取个人报销单数据
    getbxList(data):Observable<any>
    {
         return this.http.post(AppConfig.baseUrl+"Mine/BxdList",this.getId(data));
   
    }

     //获取个人报销单搜索
     getbxSearch(data):Observable<any>
     {
        return this.http.post(AppConfig.baseUrl+"Mine/searBxd",this.getId(data));
    
     }

     //获取借款搜索数据
     getjkSearch(data):Observable<any>
     {
        return this.http.post(AppConfig.baseUrl+"Mine/jksearch",this.getId(data));
    
     }


    //获取个人报销单搜索
    getbxinfo(data):Observable<any>
    {
       return this.http.post(AppConfig.baseUrl+"Reimbursement/bxdmxInfo",this.getId(data));
   
    }

    //获取图片点击后的详情
    getimginfo(data):Observable<any>
    {
       return this.http.post(AppConfig.baseUrl+"Reimbursement/imgInfo",this.getId(data));
   
    }

    getimglist(data):Observable<any>
    {
       return this.http.post(AppConfig.baseUrl+"Reimbursement/imgCount",this.getId(data));
   
    }

    //报销单当前状态
    getBXDz(data):Observable<any>
    {
       return this.http.post(AppConfig.baseUrl+"Reimbursement/bxdtype",this.getId(data));
   
    }


    //根据报销单删除所有信息
    getDelBxd(data):Observable<any>
    {
       return this.http.post(AppConfig.baseUrl+"Reimbursement/Delbxd",this.getId(data));
   
    }

    //获取当前报销单选择的类型
    getbxdtypemc(data):Observable<any>
    {
       return this.http.post(AppConfig.baseUrl+"Reimbursement/bxd_mx_type",this.getId(data));
   
    }

    //调用退回发票的接口
    getbxdfp(data):Observable<any>
    {
       return this.http.post(AppConfig.baseUrl+"Reimbursement/editRe",this.getId(data));
   
    }

    // 晓楼 add
    //调用获取借款单内容的接口
    getjkd(data):Observable<any>
    {
       return this.http.post(AppConfig.baseUrl+"Reimbursement/get_jkd",this.getId(data));
   
    }
    //调用启动流程接口
    gettestflow(data):Observable<any>
    {
      return this.http.post(AppConfig.baseUrl+"Flow/testflow",this.getId(data));

    }

    //调用实际付款人
    getPayee():Observable<any>
    {
      return this.http.get(AppConfig.baseUrl+"Flow/payee");

    }


    //pdf文件上传功能
    getpdffile(data):Observable<any>
    {
      return this.http.post(AppConfig.baseUrl+"file/pdf",data);

    }
    //pdf文件列表
    getpdflist(data):Observable<any>
    {
      return this.http.post(AppConfig.baseUrl+"pdf/list",data);

    }
    //pdf删除功能
    getDelpdf(data):Observable<any>
    {
      return this.http.post(AppConfig.baseUrl+"pdf/del",data);

    }
    //pdf详情
    getPdfInfo(data):Observable<any>
    {
      return this.http.post(AppConfig.baseUrl+"pdf/info",data);

    }


  //把所有参数转换成json字符
  private getId(e){
    const body = JSON.stringify([ 
     e
  ]);
  return body;

  }
}