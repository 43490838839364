import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import Swiper from "swiper";
import { ToastrService } from 'ngx-toastr';
import * as localForage from "localforage";

import { BxdService } from '../../server/bxd.service'
@Component({
  selector: 'app-fillaccessor',
  templateUrl: './fillaccessor.component.html',
  styleUrls: ['./fillaccessor.component.scss']
})
export class FaillaccessorComponent implements OnInit {
  title = 'swiper01';
  public id: string='';   //图片ID
  public bxdbh: string;  //报销单编号
  public data: any = [];  //附件数据
  public img_url: string = '';  //图片地址
  public bxd_status: string = "";   //报销单状态
  public activeIndex=0 //设置滑动索引
  public imgLists:any=[]
  public bxr_id:number=0;  //报销人ID
  public user_id:number; //当前登录人iD
  public mx_id:number;
  public stauts:string='';

  public modal:any={
    modalShow: false,
    text: '是否确认删除',
    btnText: ['取消','确认'],
    yesfunc:''
  }


  constructor(private router: ActivatedRoute, private bxds: BxdService, private toastr: ToastrService) {
    let that = this;

    localForage.getItem("user").then(function(value:any){
      if(value !=null){
        that.user_id=value.id;
      
      }
    })

    that.id = that.router.snapshot.params['id']

    that.bxdbh = that.router.snapshot.params['bxdbh'];
    that.mx_id = that.router.snapshot.params['mx_id'];

    that.stauts = that.router.snapshot.params['stauts'];

    that.imgList();
    
  }

  //调用所有发票数据
  imgList(){
    let that=this;
    that.bxds.getimglist({"mx_id":that.mx_id}).subscribe(res=>{

     if(that.id==''){
        that.id=res.data[0].ID
     }else{

     }
     that.imgLists=res.data;

     //获取索引值
     let index = that.imgLists.findIndex(
            element => element.ID ===that.id); 

      that.activeIndex=index;
  
      that.imginfo();
     
     
    })
  }


  //删除
  del(e,types){
    let that=this;

    //当报销人,跟登录人一致则可删除
    if(that.user_id ==that.bxr_id ){
      if(that.bxd_status =='未报销' || that.bxd_status=='已退回'){
            //  // console.log(index)
        this.modal.modalShow = true;
        this.modal.yesfunc=function(){
        //调用删除附件功能
        that.bxds.getDelimg({id:e,type:types,mx_id:that.mx_id}).subscribe(res=>{
          if(res.success==200){
            that.toastr.success(res.mes,'',{timeOut:1000});
            
            that.imgList();
          }else{
            that.toastr.error(res.mes,'',{timeOut:1000})

          }
        })
      }
      }
    
    }
   
  
  }
 
  //上传附件
  fileChanges(file) {

    let that = this;

    console.log(file);
    if (file.length === 0) {
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file[0]) // input.files[0]为第一个文件
    reader.onload = () => {

      let formData: FormData = new FormData();
      let a: any = reader.result;
      formData.append('file', a);

      formData.append('type', '附件');

      formData.append('bxd_bh', that.bxdbh);

      formData.append('bxd_mx_id', '0');

      formData.append('img_id', that.id);
      //上传附件
      that.filess(formData);
    }

  }

  //上传接口
  filess(form) {

    let that = this;
    that.bxds.getfile(form).subscribe(res => {
      that.toastr.error(res.mes, '', { timeOut: 1500 });
      that.imginfo();
     // that.initSwiper();
    })
  }

  //点击加号进行上传
  upload() {
    //触发上传附件功能
    let that=this;
    if(that.bxr_id ==that.user_id){
      var oInput = document.getElementById("enc");

      oInput.click();
    }
   
  }

  //获取发票详情接口
  imginfo() {
    let that = this;
    that.bxds.getimginfo({ "id": that.id, "bxdbh": that.bxdbh }).subscribe(res => {

      that.img_url = res.data.data[0].IMG_S_URL
      that.data = res.data.data[0].ENC;
      that.bxd_status = res.data.staut_bxd[0].STATUS,
      that.bxr_id=res.data.staut_bxd[0].BXR_ID;
      //that.initSwiper();
    })
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.initSwiper();
    }, 200);
  }

  
  initSwiper() {
  
    let that=this;
    console.log(that.activeIndex)
    const swiper = new Swiper('.swiper-container', {
      slidesPerView: 2.4,
      spaceBetween: 10,
      observer: true,
      observeParents: true
     
     
    });
    const ticket = new Swiper('.ticket', {
      initialSlide :that.activeIndex,
      spaceBetween: 30,
      observer: true,
      observeParents: true,
      on: {
        slideChangeTransitionStart: function(){
          that.activeIndex=this.activeIndex;
        //   //根据索引替换发票ID以及对应的附件数据
        console.log(that.imgLists);
       
            that.id=that.imgLists[this.activeIndex].ID;
           
             that.imginfo();
       
         
       }
      },
    });


  }


}
