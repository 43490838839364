import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router'
import {HomesService} from '../../server/homes.service'
import * as localForage from "localforage";
import { AppConfig } from 'src/app/confi/app-config';

@Component({
  selector: 'app-psearch',
  templateUrl: './psearch.component.html',
  styleUrls: ['./psearch.component.scss']
})
export class PsearchComponent implements OnInit {
  public listData:any=[]
 public ws_count:number=0;
 public ws_price:any=0;
 public searchValue:string=''
 public bh:string='';
 public type:number=0; //报销/借款
 public key:number=0; //待付款/已付款
 public history:any=[];
     //弹出提示框内容
     public modal: any = {
      modalShow: false,
      text: '确认是否已付款',
      btnText: [],
      yesfunc: '',
      canclefunc: '',
    }
  public id=0 //当前登录人ID
  public company_id=0; //当前公司ID

  constructor(private rou:Router,private rout:ActivatedRoute,private homes:HomesService) { 
    let that=this;
    that.type=that.rout.snapshot.params['type'];
    that.key=that.rout.snapshot.params['key'];
    this.searchValue=AppConfig.searchValue;
    localForage.getItem("fuhistory").then(function(value:any){
      if(value !=null){
        that.history=value;
      }
    })

    localForage.getItem("user").then(function(value:any){
      that.id=value.id;
      that.company_id=value.company_id

      if(that.searchValue !=''){
        console.log("Aaa");
        that.payList();
      }
      
    })

   
  }


  //调用付款接口数据
  payList(){
    let that=this;
    that.homes.getPyaList({'types':that.type,'type':that.key,'search':this.searchValue,'id':that.id,'company_id':that.company_id}).subscribe(res=>{
      if(res.success==200){
       
       

        
       
        that.listData=res.data.bxd_list

        that.ws_count=that.listData.length;
        that.listData.forEach(element => {
            that.ws_price=(Number(that.ws_price)+Number(element.PRICE)).toFixed(2)
        });


        that.listData.types=that.type;
        console.log( that.ws_price);
        console.log(that.listData);

      }else{
        that.ws_count=0;
        that.ws_price=0;
        that.listData=[]
      }
   

      console.log(res);
    })

  }

  ngOnInit(): void {
  }

  //点击搜索
  sear(){
    let that=this;
    that.ws_price=0
    AppConfig.searchValue=this.searchValue;
    that.payList();
  }

  //取消输入值
  quxiao(){
    this.searchValue='';
    AppConfig.searchValue=this.searchValue
    this.listData=[];
  }

  //清空所有历史
  delAll(){
    let that=this;
    that.history=[];
    localForage.removeItem("fuhistory");
  }

  //删除某个历史
  del(e){
    this.history.splice(e,1);
    localForage.setItem("fuhistory",this.history);
  }

  //点击某个历史进行搜索
  diansear(e){
    let that=this;
    that.ws_price=0

    that.searchValue=e.substring(3);
    AppConfig.searchValue=that.searchValue;
    that.payList();
  }
  //回车键搜索
  search(e){

    let that=this;
    that.ws_price=0

    if(e.keyCode==13){
      if(that.history.length>=5){
        this.history.splice(0,1);
      }
      if(this.searchValue !=''){
        that.history.push('No.'+this.searchValue);

      }
      AppConfig.searchValue=this.searchValue;
      localForage.setItem("fuhistory",that.history);

      that.payList();

    }
  }

  //点击付款按钮操作
  fukuan(){
    let that=this;
    if( that.listData.length>0){

     
      that.modal.modalShow=true;
      that.modal.btnText=['取消','确认']
      that.modal.yesfunc=function(){

          that.listData.forEach(element => {
            that.bh=that.bh+','+element.BXD_BH
          });
          
          that.homes.getPyaEdits({"bxdbh": that.bh.substr(1),"type":that.type}).subscribe(res=>{

              that.payList();
          })
      }
    }
  }

}
