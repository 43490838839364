//路由拦截功能

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as localForage from "localforage";
import {ToastrService} from 'ngx-toastr'
@Injectable()
export class Pay implements CanActivate {
    constructor(private router: Router,private toast:ToastrService) {
    }

    async canActivate() {
        let isLogin=true;
        let that=this;
       
        const user:any=await localForage.getItem('user')
        console.log(user);
        if(user !=null && (user.is_pay==1)){
            isLogin= true;
           
        }else{
            
             isLogin=false;
             that.toast.error("您无权访问此功能",'',{timeOut:1000})
             //that.router.navigate(["/home"])
        }
        return isLogin
    
       
    }
}
