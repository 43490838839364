import { Component, OnInit } from '@angular/core';
import {HomesService} from '../../server/homes.service'
import {Router} from '@angular/router'
import { AppConfig } from 'src/app/confi/app-config';
import * as localForage from "localforage";

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss']
})
export class RecordComponent implements OnInit {

  public index:number = 0;   //一级菜单
  public key : number = 0;//二级菜单
  public listData:any=[];
  public y_count=0; //已付款总比数
  public w_count=0; //未付款总比数
  public y_price=0; //已付款总和
  public w_price=0; //未付款总和

  public ws_price=0 //底部总数
  public ws_count=0 //底部总笔数
  public id=0 //当前登录人ID
  public company_id=0; //当前公司ID
  constructor(private homes:HomesService,private rou:Router) { 
   
    let that=this;
    that.index=AppConfig.fuktype,
    that.key=AppConfig.fukkey,
    localForage.getItem("user").then(function(value:any){
      that.id=value.id;
      that.company_id=value.company_id
      that.payList();

    })

  }

  ngOnInit(): void {
  
  }

  //调用付款接口数据
  payList(){
    let that=this;
    that.homes.getPyaList({'types':AppConfig.fuktype,'type':AppConfig.fukkey,'id':that.id,'company_id':that.company_id}).subscribe(res=>{
      if(res.success==200){
        that.w_count=res.data.t_price[0].W_COUNT;
        that.y_count=res.data.t_price[0].Y_COUNT;
        that.w_price=res.data.t_price[0].W_PRICE;
        that.y_price=res.data.t_price[0].Y_PRICE;
  
        if(this.key==0){
          that.ws_count=res.data.t_price[0].W_COUNT;
          that.ws_price=res.data.t_price[0].W_PRICE;
        }else{
          that.ws_count=res.data.t_price[0].Y_COUNT;
          that.ws_price=res.data.t_price[0].Y_PRICE;
  
        }
        that.listData=res.data.bxd_list

        that.listData.types=that.index;

      }else{
        that.w_count=0;
        that.y_count=0;
        that.w_price=0;
        that.y_price=0;
        that.ws_count=0;
        that.ws_price=0;
        that.listData=[]
      }
   

      console.log(res);
    })

  }

  type(index){
    this.index = index;
    AppConfig.fuktype=index;
    this.payList();
  }
  classify(index){

    this.key=index ;
    AppConfig.fukkey=index;

    this.payList();
  }


  //搜索页面
  sear(){
    //[routerLink]="['/psearch']"
    this.rou.navigate(["/psearch",{type:this.index,key:this.key}])
  }

}
