import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'

import { BxdService } from '../../server/bxd.service'

import { ToastrService } from 'ngx-toastr';

import * as localForage from "localforage";

@Component({
  selector: 'app-choosetype',
  templateUrl: './choosetype.component.html',
  styleUrls: ['./choosetype.component.scss']
})
export class ChoosetypeComponent implements OnInit {

  public type: string = "";  //点击进入的报销单类型
  public data: any = {}; //接口报销参数

  public dataMx: any = {}; //接口明细参数
  public tel: string = "";
  public type_list: any;
  public bxdbh: string = ""; //生成的编号
  public type_id: string = ""; //获取点击后的类型
  public bxds_list: any = [];
  constructor(
    private route: Router,
    private rou: ActivatedRoute,
    private bxd: BxdService,
    private toastr: ToastrService,
  ) {
    let that = this;
    this.rou.queryParams.subscribe(res => {
      if (res.bxdbh != undefined) {
        that.type = res.type;
        that.bxdbh = res.bxdbh;
      }

    })

    //调用当前登录的手机号
    //当报销单编号为空则创建bxd
    if (that.bxdbh == '') {
      localForage.getItem("user").then(function (value: any) {
        that.tel = value.tel;
        console.log(that.tel);
        //that.bxdAdd();
      })
    }


    this.bxdType();
    this.jktypemc();

  }

  //获取当前选中的借款名称
  jktypemc() {
    let that = this;
    that.bxd.getbxdtypemc({ "bxdbh": that.bxdbh }).subscribe(res => {
      that.bxds_list = res.data;
      console.log(res);
    })
  }

  //调用类别数据
  bxdType() {
    let that = this;
    that.bxd.getbxType({ "type": that.type }).subscribe(res => {
      that.type_list = res.data;

    })
  }




  //获取点击的数据并加显示
  types(e, id) {
    let that = this;
    let ids = '';
    //判断当前是否选中，选择则取消
    if (that.type_list[e]['ACTIVE'] == 'active') {
      that.type_list[e]['ACTIVE'] = '';
    } else {

      if (that.bxdbh != '') {

        //判断总数量大于1时
        if (that.bxds_list.length > 1) {
          if (that.type_list[e]['NAME'] == '评审费') {
            that.toastr.error("当前单号只可选择普通类型", '', { timeOut: 1000 });
            return false;
          } else {

            that.type_list[e]['ACTIVE'] = 'active';
            console.log(that.type_list);
          }

        }


        //判断总数量为1时
        if (that.bxds_list.length == 1) {

          if (that.bxds_list[0]['BXMC'] == '评审费') {
            if (that.type_list[e]['NAME'] != '评审费') {
              that.toastr.error("当前单号只可选择评审费", '', { timeOut: 1000 });
              return false;
            } else {
              that.type_list[e]['ACTIVE'] = 'active';

            }

          } else {
            if (that.type_list[e]['NAME'] != '评审费') {
              that.type_list[e]['ACTIVE'] = 'active';

            } else {
              that.toastr.error("当前单号只可选择其他类型", '', { timeOut: 1000 });
              return false;
            }
          }
        }

        if (that.bxds_list.length == 0) {

          if (that.type_list[e].NAME == '评审费') {
            var a = true
            for (var i = 0; i < that.type_list.length; i++) {
              if (that.type_list[i].NAME != '评审费' && that.type_list[i].ACTIVE == 'active') {
                a = false
                that.toastr.error("只可选择其他类型", '', { timeOut: 1000 });
                break;
              }
            }
            if (a == true) {

              that.type_list[e]['ACTIVE'] = 'active';

            }

          } else {
            var b = true
            for (var i = 0; i < that.type_list.length; i++) {
              if (that.type_list[i].NAME == '评审费' && that.type_list[i].ACTIVE == 'active') {
                b = false
                that.toastr.error("只可选择评审费", '', { timeOut: 1000 });
                break;
              }
            }

            if (b == true) {

              that.type_list[e]['ACTIVE'] = 'active';

            }
          }
        }
      } else {

        if (that.type_list[e].NAME == '评审费') {
          var a = true
          for (var i = 0; i < that.type_list.length; i++) {
            if (that.type_list[i].NAME != '评审费' && that.type_list[i].ACTIVE == 'active') {
              a = false
              that.toastr.error("只可选择其他类型", '', { timeOut: 1000 });
              break;
            }
          }
          if (a == true) {

            that.type_list[e]['ACTIVE'] = 'active';

          }

        } else {
          var b = true
          for (var i = 0; i < that.type_list.length; i++) {
            if (that.type_list[i].NAME == '评审费' && that.type_list[i].ACTIVE == 'active') {
              b = false
              that.toastr.error("只可选择评审费", '', { timeOut: 1000 });
              break;
            }
          }

          if (b == true) {

            that.type_list[e]['ACTIVE'] = 'active';

          }
        }
      }





    }




    //获取选择的id号
    that.type_list.forEach(element => {
      if (element.ACTIVE == 'active') {
        ids += ',' + element.ID
      }

    });

    that.type_id = ids.substr(1);
  }

  //点击下一步触发添加至明细表中
  next() {
    let that = this;
    //判断是否已选择相应类型
    if (that.type_id == '') {
      that.toastr.error("请选择类别", '', { timeOut: 1000 });
      return false;

    } else {


      that.data.tel = that.tel;

      that.data.type = that.type;


      if (that.bxdbh != '') {
        that.bxda();

      } else {

        if (that.data.tel == '') {
          that.toastr.error("登录信息有误", '', { timeOut: 1000 });
          return false;
        }

        //调用生成编号规则接口
        that.bxd.getBxdAdd(that.data).subscribe(res => {
          that.bxdbh = res.data;
          that.bxda();

          console.log(that.bxdbh);
        })
      }
    }
  }

  bxda() {
    let that = this;
    that.dataMx.bxd_bh = that.bxdbh;
    that.dataMx.bxd_type = that.type_id;

    //查询当前报销单状态
    that.bxd.getBXDz({ "bxdbh": that.dataMx.bxd_bh }).subscribe(res => {
      if (res.success == 200) {
        if (res.data[0].STATUS == '审批中') {
          that.toastr.error("当前报销单正在审批中", '', { timeOut: 1000 });
        } else {
          //跳转添加类型修改中
          that.bxd.getbxdmxAdd(that.dataMx).subscribe(res => {
            //跳转至报销类别列表中
            that.route.navigate(['/noticket', { 'bxd_bh': that.bxdbh }])
          })

        }
      } else {
        that.toastr.error(res.mes, '', { timeOut: 1000 });
      }
    })



  }



  ngOnInit(): void {
  }

}
