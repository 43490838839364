import { Component, OnInit } from '@angular/core';
import {MessageService} from '../../server/message.service'
import {ActivatedRoute,Router} from '@angular/router'
import * as localForage from "localforage";
import { ToastrService } from 'ngx-toastr';
import { typeWithParameters } from '@angular/compiler/src/render3/util';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  public index:number=0;
  
  public data:any={};  //参数类型
  public user_id:string=''; 
  public lxbh:any='报销通知';
  public listData:any=[]

  constructor(private mess:MessageService,private rou:Router,private toastr:ToastrService) { 
    let that=this;
      localForage.getItem("user").then(function(value:any){
          that.user_id=value.id;
          that.messList();
      })
  }

  ngOnInit(): void {
  }

  //调用消息列表接口
  messList(){
    let that=this;
    that.data.jsr=that.user_id;
    that.data.lxbh=that.lxbh;
    that.mess.getMessagelist(that.data).subscribe(res=>{
      if(res.success==200){
        that.listData=res.data;
      }else{
          that.listData=[]
          //that.toastr.error(res.mes,'',{timeOut:1000});
      }
    })
  }


  type(index){
    console.log(this.data)
    this.index = index;
    if(index==0){
     this.lxbh='报销通知'
    }else{
      this.lxbh='审批通知'
    
    }
    this.messList();
  }

}
