//路由拦截功能

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import * as localForage from "localforage";
import {HomesService} from '../server/homes.service'
import {ToastrService} from 'ngx-toastr'
@Injectable()
export class Login implements CanActivate {
    constructor(private router: Router,private homes:HomesService,private toast:ToastrService) {
    }

    async canActivate() {
        let isLogin=true;
        let that=this;
        
        const user:any=await localForage.getItem('user')
        console.log(user);
        if(user !=null){
            //  isLogin= false;
            // that.router.navigate(['/home'])
           
             //调用首页数据判断是否是否存在
             that.homes.getHome({'tel':user.tel}).subscribe(res=>{
                //存在返回至首页中
                if(res.success==200){
                    isLogin= false;
                    that.router.navigate(['/home'])
                }else{
                    //否则清空缓存并回至登录页
                    isLogin= true;
                    that.toast.error(res.mes,'',{timeOut:1000})
                    localForage.clear();
                   // that.router.navigate(['/login'])
                }        
              })
        }else{
            
             isLogin= true;
        }
        return isLogin
    
       
       
        //console.log(user);
        // }
        // if (JSON.stringify(user) === '{}') {
        //     isLogin = false;
        //     // 未登入跳转到登入界面
        //     
        // } else {
        //    
        // }
        // return isLogin;
       
    }
}
