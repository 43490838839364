import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router'
import {BxdService} from '../../server/bxd.service'
import {BorrowService} from '../../server/borrow.service'
import {ToastrService} from 'ngx-toastr'
@Component({
  selector: 'app-lists',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListsComponent implements OnInit {
  @Input()
  public contentData:any = [];
//弹出提示框内容
public modal: any = {
  modalShow: false,
  text: '是否要删除',
  btnText: [],
  yesfunc: '',
  canclefunc: '',
}
  constructor(private ro:Router,private borrow:BorrowService, private bxds:BxdService,private toast:ToastrService) { 
  }

  ngOnInit(): void {
  }

  //点击进入详情
  baox(status,jkd,id){
    console.log(status);
    if(status=='未提交'){
      this.ro.navigate(['/loanapply',{jk_bh:jkd}]);
    }else{
      this.ro.navigate(['/jkinfo',{jk_bh:jkd,type:status,id:id}]);

    }
  }

  //点击进入详情页
  deil(jkd,status,id){
      console.log(status);
    if(status=='未提交'){
      this.ro.navigate(['/loanapply',{jk_bh:jkd}]);
    }else{
      this.ro.navigate(['/jkinfo',{jk_bh:jkd,type:status,id:id}]);

    }
  }

  delete(index,id,status){
    let that=this;
    if(status=='未提交' || status=='已退回'){
      that.modal.modalShow=true;
      that.modal.text='是否要删除?';
      that.modal.btnText=['取消','确认'];

      that.modal.yesfunc=function(){
        that.borrow.getdeljkd({"jkd_bh":id}).subscribe(res=>{
          if(res.success==200){
              that.contentData.splice(index,1);
          }else{
            that.toast.error('删除失败','',{timeOut:1000})

          }
        })
      }
     
    }else{
      that.toast.error("只可删除未报销、已退回单据",'',{timeOut:1000})
    }
   
  }

}
