//服务必引入injectable
import {Injectable} from '@angular/core';

//自定义配置文件
import {AppConfig} from "../confi/app-config";


//obj对象
import { Observable } from 'rxjs';

import { HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
@Injectable()

export class FlowService {

    httpOptions:any;
    
    constructor(private http: HttpClient) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    }
//把所有参数转换成json字符
  private getId(e){
        const body = JSON.stringify([ 
        e
        ]);
    return body;

  }



    //调用审核流程
    getflowjk(data):Observable<any>
    {
       return this.http.post(AppConfig.baseUrl+"flow/flow_jk_deal",this.getId(data));
   
    }
    

}