import { Component, OnInit } from '@angular/core';

import { HomesService } from '../server/homes.service'
import { Router } from '@angular/router'
import * as localForage from "localforage";
import { BxdService } from '../server/bxd.service'
import { ToastrService } from 'ngx-toastr'
import { AppConfig } from '../confi/app-config';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public tel: string = "";
  public name: string = "";
  public data: any = {}; //接口报销参数
  public type: any;
  public bxdbh: any;
  constructor(private homes: HomesService, private ro: Router, private bxd: BxdService, private toast: ToastrService) {
    let that = this;
    // //调用缓存中的手机号
    localForage.getItem("user").then(function (value: any) {
      that.tel = value.tel;
      that.homeIndex();

    })


  }



  //点击创建报销单
  //调用创建报销单
  bxdAdd(e) {
    let that = this;
    // that.data.tel=that.tel;

    // that.data.type='普通';
    // that.bxd.getBxdAdd(that.data).subscribe(res=>{
    //   that.bxdbh=res.data;

    that.ro.navigate(['/choosetype'], {
      queryParams: {
        type: e,
        bxdbh: ''
      }
    })
    //   console.log(that.bxdbh);
    // })
  }

  //跳转至我的报销页面
  bx() {
    this.ro.navigate(['/mylist'])
  }

  //跳转至借款页面
  jk() {
    this.ro.navigate(['/loanlist'])
  }


  //跳转付款记录
  fupay() {
    this.ro.navigate(['/paymember'])

  }

  //调用首页数据
  homeIndex() {
    let that = this;
    that.homes.getHome({ 'tel': that.tel }).subscribe(res => {
      console.log(res)
      if (res.success == 200) {
        that.name = res.data[0].NAME
      } else {
        localForage.clear();
        that.toast.error(res.mes, '', { timeOut: 1000 })
        that.ro.navigate(["/login"]);
      }

    })
  }

  ngOnInit(): void {
  }

}
