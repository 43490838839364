import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router'
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import * as localForage from "localforage";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  public name:string='';
  public tel:string='';
  public pwd:string=''
  constructor(private rout:ActivatedRoute,private ro:Router) {

      let that=this;
      that.rout.queryParams.subscribe(res=>{
        that.name=res.name
        that.tel=res.tel
        that.pwd=res.pwd
      })
     

   }

  ngOnInit(): void {
  }

  //退出登录
  relogin(){
    let that=this;
    localForage.clear().then(function() {
      // 当数据库被全部删除后，此处代码运行
      that.ro.navigate(['/login'])
  }).catch(function(err) {
      // 当出错时，此处代码运行
      console.log(err);
  });
    //localForage.clear();

   
  }

}
