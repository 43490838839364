import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../server/login.service'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router'
import { validateRex } from '../../validate/validate-register';
import * as localForage from "localforage";
import { AppConfig } from 'src/app/confi/app-config';

@Component({
  selector: 'app-loginfrom',
  templateUrl: './loginfrom.component.html',
  styleUrls: ['./loginfrom.component.scss']
})
export class LoginfromComponent implements OnInit {
  public code: string; //微信授权后的code
  public name: string; //用户名
  public heard: string; //头像
  //public openid: string; //微信信息
  public unionid: string; //微信信息unionid
  public user: any = {}; //定义缓存

  public secret: boolean = true;      //密码是否加密
  public fill: boolean = false;    //是否输入完全
  public tel: string = '';
  public pwd: string = '';
  public formModel: FormGroup;  //表单
  public data: any = {};  //定义传参数据
  // public openid:string='oZFuYwNfHObIO7pfHXvP6Ecoro7c'; //Dczopenid
  // public openid:string = 'oZFuYwIY_6U5457n_YK0HCHjJl6w';  //wl的openid
  // public openid:string = 'oZFuYwFOY5aT7ivTofZdnuAw-oks'; // 
  // public openid:string = 'oZFuYwJ4enDtdgb4YhsSFKKf728k';
  // public openid: string = 'oZFuYwM8yCSviWP9Y-lGa1sKCDEc';  // z
  public openid: string = ''; //openid
  public formErrors = {
    tel: '',
    pwd: ''
  };
  // 为每一项表单验证添加说明文字
  validationMessage = {
    'tel': {
      'required': '',
      'notdown': '手机号不正确',
    },
    'pwd': {
      'required': '密码不能为空',
    }
  };

  constructor(private fb: FormBuilder,
    private logins: LoginService,
    private toastr: ToastrService,
    private route: Router,
    private routes: ActivatedRoute
  ) {


  }



  // 构建表单方法
  buildForm(): void {
    // 通过 formBuilder构建表单
    this.formModel = this.fb.group({

      'tel': ['', [
        Validators.required,
        validateRex('notdown', /^(((13[0-9]{1})|(15[0-9]{1})|(17[0-9]{1})|(18[0-9]{1}))+\d{8})$/),
      ]],
      'pwd': ['', [Validators.required

      ]]

    });

    // 每次表单数据发生变化的时候更新错误信息
    this.formModel.valueChanges
      .subscribe(data => this.onValueChanged(data));
  }
  // 每次数据发生改变时触发此方法
  onValueChanged(data?: any, dirty?) {
    // 如果表单不存在则返回
    if (!this.formModel) return;
    // 获取当前的表单
    const form = this.formModel;

    // 遍历错误消息对象
    for (const field in this.formErrors) {
      // 清空当前的错误消息
      this.formErrors[field] = '';
      // 获取当前表单的控件
      const control = form.get(field);

      if (dirty) {
        // 当前表单存在此空间控件 && 此控件没有被修改 && 此控件验证不通过
        if (control && !control.valid) {
          // 获取验证不通过的控件名，为了获取更详细的不通过信息
          const messages = this.validationMessage[field];
          // 遍历当前控件的错误对象，获取到验证不通过的属性
          for (const key in control.errors) {
            // 把所有验证不通过项的说明文字拼接成错误消息
            this.formErrors[field] += messages[key] + '\n';
          }
        }
      } else {
        // 当前表单存在此空间控件 && 此控件没有被修改 && 此控件验证不通过
        if (control && control.dirty && !control.valid) {
          // 获取验证不通过的控件名，为了获取更详细的不通过信息
          const messages = this.validationMessage[field];
          // 遍历当前控件的错误对象，获取到验证不通过的属性
          for (const key in control.errors) {
            // 把所有验证不通过项的说明文字拼接成错误消息
            this.formErrors[field] += messages[key] + '\n';
          }
        }
      }


    }
  }
  ngOnInit(): void {
    let that = this;
    //判断缓存是否存在
    localForage.getItem("user").then(function (value: any) {
      if (value == null) {

        that.routes.queryParamMap.subscribe((params: any) => {

          that.code = params.get('wx_user');
          if (that.code) {
            that.name = JSON.parse(that.code).nickname;
            that.heard = JSON.parse(that.code).headimgurl;
            that.openid = JSON.parse(that.code).openid;
            that.unionid = JSON.parse(that.code).unionid;
            AppConfig.user = { "openid": that.openid, "unionid": that.unionid }
            AppConfig.user.openid = that.openid;
            AppConfig.user.unionid = that.unionid
          } else {
            // console.log('跳转至微信授权');
            window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + AppConfig.appid + "&redirect_uri=" + AppConfig.redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=aglogin#wechat_redirect";
          }
        });


      } else {
        AppConfig.user.openid = value.openid;
        AppConfig.user.unionid = value.unionid
        that.openid = value.openid;
      }
    })

    this.buildForm();
  }

  //密码加密解密
  encrypt() {
    this.secret = !this.secret;
  }

  inputt() {
    let that = this;
    if (that.formModel.valid) {
      this.fill = true;
    } else {
      this.fill = false;
    }
  }

  //登录方法
  onSubmit() {
    let that = this;
    //当fill为true可点击
    if (that.formModel.valid) {
      let formValue = that.formModel.value;

      that.data.tel = formValue.tel;
      that.data.pwd = formValue.pwd;
      that.data.openid = that.openid;  //授权后换成活的

      //调用登录接口
      that.logins.getLogin(that.data).subscribe(res => {
        if (res.success == 200) {
          AppConfig.user.tel = that.data.tel;
          AppConfig.user.id = res.data[0].ID;
          AppConfig.user.role_id = res.data[0].ROLE_ID
          AppConfig.user.is_pay = res.data[0].IS_PAY
          AppConfig.user.company_id = res.data[0].COMPANY_ID
          //将手机号存入缓存中
          localForage.setItem("user", AppConfig.user).then(function () {
            that.route.navigateByUrl("/home");

          });
          //跳转至首页
        } else {
          that.toastr.error(res.mes, '', { timeOut: 1000 });

        }
      })
    } else {
      that.onValueChanged('', true);
    }

  }




}
