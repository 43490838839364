import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router'
import {ApprService} from '../../server/appr.service'
import * as localForage from "localforage";

@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss']
})
export class ApproveComponent implements OnInit {

  public index:number=0;
  public listData:any=[];
  public types:string='普通';
  public user_id:string; //登录ID
  constructor(private appr:ApprService,private rou:Router) { 
    let that=this;
    localForage.getItem("user").then(function(value:any){
      if(value !=null){
          that.user_id=value.id;
          
          that.apprList();
      }
    })

  }

  ngOnInit(): void {
  }
  //点击选项卡
  type(index){
    this.index = index;
    if(index==1){
      this.types='借款'

    }else{
      this.types='普通'
     
    }
    this.apprList();
  }


 

  //调用审批列表页
  apprList(){
    let that=this;
    that.appr.getApprList({"id":that.user_id,"type":that.types}).subscribe(res=>{
      if(res.data.length>0){
        that.listData=res.data;
        that.listData.type=this.types
      }else{
        that.listData=[];
      }
      
    })
  }

}
