//服务必引入injectable
import {Injectable} from '@angular/core';

//自定义配置文件
import {AppConfig} from "../confi/app-config";


//obj对象
import { Observable } from 'rxjs';

import { HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
@Injectable()

export class MineService {

    httpOptions:any;
    
    constructor(private http: HttpClient) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    }

    //把所有参数转换成json字符
  private getId(e){

        const body = JSON.stringify([ 
        e
        ]);
        return body;

 }

 //个人信息
    getMinelist(data):Observable<any>{
        return this.http.post(AppConfig.baseUrl+"Mine/index",this.getId(data));

    }

  //个人借款列表  
  getMineJk(data):Observable<any>{
    return this.http.post(AppConfig.baseUrl+"Mine/jkdList",this.getId(data));

  }

  //查询实际付款人
  getPayee(data):Observable<any>{
    return this.http.get(AppConfig.baseUrl+"payee/list",{
      params:data
    });
  }

  //查询详情
  getPayeeInfo(data):Observable<any>{
    return this.http.post(AppConfig.baseUrl+"payee/info",data);

  }

  //添加实际收款人
  getPayeeCreate(data):Observable<any>{
    return this.http.post(AppConfig.baseUrl+"payee/PayCreate",data);

  }

  //删除实际收款人
  getPayeeDel(data):Observable<any>{
    return this.http.post(AppConfig.baseUrl+"payee/del",data);

  }

  //修改实际收款人
  getPayUpdate(data):Observable<any>{
    return this.http.post(AppConfig.baseUrl+"payee/PayUpdate",data);

  }
}