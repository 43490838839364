import {Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss']
})

export class LoadComponent implements OnInit {
  @Input()
  public modalContent:any
  @Output()
  private modalContentChange:EventEmitter<any>=new EventEmitter();

  constructor() { 

    

  }

  ngOnInit(): void {
  }

}
