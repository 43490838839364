import { Component, OnInit } from '@angular/core';
import * as localForage from "localforage";
 import {MineService} from '../../server/mine.service'
 import {ToastrService} from 'ngx-toastr'
@Component({
  selector: 'app-mine',
  templateUrl: './mine.component.html',
  styleUrls: ['./mine.component.scss']
})
export class MineComponent implements OnInit {
  public tel="";
  public name:string='';
  public heard:string='';
  public dep_name:string='';
  public pwd:string=''
  constructor(private mine:MineService,private toastr:ToastrService) { 
    let that=this;
    localForage.getItem("user").then(function(value:any){
      if(value !=null){
        that.tel=value.tel;

        that.mineInfo();
      }
    })

  }

  //调用个人信息
  mineInfo(){
    let that=this;
    that.mine.getMinelist({"tel":that.tel}).subscribe(res=>{
      if(res.success==200){
        that.tel=that.tel;
        that.name=res.data[0].NAME;
        that.heard=res.data[0].HEAD;
        that.dep_name=res.data[0].DEPARTMENT_NAME;
        that.pwd=res.data[0].PWD
      }else{
        that.toastr.error(res.mes,'',{timeOut:1000})
      }
    

    })
  }

  ngOnInit(): void {
  }

}
