import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from './app-routing.module';
import { TouchSlideModule } from './touch-slide/touch-slide.module';
import { AppComponent } from './app.component';
import { ToppartComponent } from './login/toppart/toppart.component';
import { LoginfromComponent } from './login/loginfrom/loginfrom.component';
import { EnrollformComponent } from './login/enrollform/enrollform.component';
import { FindpwdComponent } from './login/findpwd/findpwd.component';
import { PwdcomfirmComponent } from './login/pwdcomfirm/pwdcomfirm.component';
import { HomeComponent } from './home/home.component';
import { TabbarComponent } from './tabbar/tabbar.component';
import { ChoosetypeComponent } from './reimburseprocess/choosetype/choosetype.component';
import { NoticketComponent } from './reimburseprocess/noticket/noticket.component';
import { FilloutComponent } from './reimburseprocess/fillout/fillout.component';
import { GeneralfilloutComponent } from './reimburseprocess/generalfillout/generalfillout.component';
import { ReviewfeeComponent } from './reimburseprocess/reviewfee/reviewfee.component';
import { AccessoryComponent } from './reimburseprocess/accessory/accessory.component';
import { CheckboxMaskComponent } from './reimburseprocess/checkbox-mask/checkbox-mask.component';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { ModalComponent } from './common/modal/modal.component';
import { LoadComponent } from './common/load/load.component';


import { SignaturePadModule } from 'angular2-signaturepad';

import { MylistComponent } from './myreimbursement/mylist/mylist.component';
import { SearchComponent } from './myreimbursement/search/search.component';
import { ListComponent } from './myreimbursement/list/list.component';
import { DetailComponent } from './reimbursedetail/detail/detail.component';
import { ApproveComponent } from './approve/approve/approve.component';
import { EnumerateComponent } from './approve/enumerate/enumerate.component';
import { HistoryComponent } from './approve/history/history.component';
import { HistorylistComponent } from './approve/historylist/historylist.component';
import { IntroComponent } from './approve/intro/intro.component';
import { ExamineComponent } from './approve/examine/examine.component';

import { MessageComponent } from './message/message/message.component';
import { MlistComponent } from './message/mlist/mlist.component'

import {RecordComponent} from './payment/record/record.component'
import {PdetailComponent} from './payment/pdetail/pdetail.component'
import {PlistComponent} from './payment/plist/plist.component'

import {MineComponent} from './mine/mine/mine.component'
import {AccountComponent} from './mine/account/account.component'
import {FaillaccessorComponent} from './reimburseprocess/fillaccessor/fillaccessor.component'

//登录服务接口
import { LoginService } from './server/login.service'
//首页服务接口
import { HomesService } from './server/homes.service'

//报销单服务接口
import { BxdService } from './server/bxd.service'

//审批接口
import { ApprService } from './server/appr.service';
//消息接口
import {MessageService} from './server/message.service'

//借款接口
import {BorrowService} from './server/borrow.service'
import {FlowService} from './server/flow.service'

//借款单还款接口
import {JkdService} from './server/jkd.service'

//个人信息接口
import {MineService} from './server/mine.service';
import { PreviewComponent } from './reimburseprocess/preview/preview.component';
import { PsearchComponent } from './payment/psearch/psearch.component';
import { LoanComponent } from './reimburseprocess/loan/loan.component';
import { LoantypeComponent } from './reimburseprocess/loantype/loantype.component';
import { LoanlistComponent } from './reimburseprocess/loanlist/loanlist.component';
import { LoanapplyComponent } from './reimburseprocess/loanapply/loanapply.component';
import { LoangeneralComponent } from './reimburseprocess/loangeneral/loangeneral.component'

import {ListsComponent} from './jkappro/list/list.component'
import {JkinfoComponent} from './reimburseprocess/jkinfo/jkinfo.component'
import {JksearchComponent} from './reimburseprocess/jksearch/jksearch.component'
import {JkshenpiComponent} from './reimburseprocess/jkshenpi/jkshenpi.component'

import {JkdetailComponent} from './payment/jkdetail/jkdetail.component';
import { JkorderinfoComponent } from './reimburseprocess/jkorderinfo/jkorderinfo.component';
import { PdfAffixComponent } from './reimburseprocess/pdf-affix/pdf-affix.component';
import { GatheringInfoComponent } from './reimburseprocess/gathering-info/gathering-info.component';
import { EditGatheringInfoComponent } from './reimburseprocess/edit-gathering-info/edit-gathering-info.component';
//拦截登录

@NgModule({
  declarations: [
    JkdetailComponent,
    JksearchComponent,
    JkshenpiComponent,
    JkinfoComponent,
    ListsComponent,
    MineComponent,
    FaillaccessorComponent,
    AccountComponent,
    PlistComponent,
    PdetailComponent,
    RecordComponent,
    AppComponent,
    LoadComponent,
    ToppartComponent,
    LoginfromComponent,
    EnrollformComponent,
    FindpwdComponent,
    PwdcomfirmComponent,
    HomeComponent,
    TabbarComponent,
    ChoosetypeComponent,
    ModalComponent,
    NoticketComponent,
    FilloutComponent,
    GeneralfilloutComponent,
    ReviewfeeComponent,
    AccessoryComponent,
    CheckboxMaskComponent,
    MylistComponent,
    SearchComponent,
    ListComponent,
    DetailComponent,
    ApproveComponent,
    EnumerateComponent,
    HistoryComponent,
    HistorylistComponent,
    IntroComponent,
    ExamineComponent,
    MessageComponent,
    MlistComponent,
    PreviewComponent,
    PsearchComponent,
    LoanComponent,
    LoantypeComponent,
    LoanlistComponent,
    LoanapplyComponent,
    LoangeneralComponent,
    JkorderinfoComponent,
    PdfAffixComponent,
    GatheringInfoComponent,
    EditGatheringInfoComponent,
    
  ],
  imports: [
    BrowserAnimationsModule,
    ToastrModule.forRoot(),

    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,

    BrowserModule,
    FormsModule,
    AppRoutingModule,
    SignaturePadModule,
    TouchSlideModule

  ],
  providers: [
    JkdService,
    FlowService,
    BorrowService,
    MineService,
    MessageService,
    ApprService,
    LoginService,
    BxdService,
    HomesService,
    { provide: LocationStrategy, useClass: PathLocationStrategy }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
