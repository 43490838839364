//服务必引入injectable
import {Injectable} from '@angular/core';

//自定义配置文件
import {AppConfig} from "../confi/app-config";


//obj对象
import { Observable } from 'rxjs';

import { HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
@Injectable()

export class HomesService {
    httpOptions:any;
    
    constructor(private http: HttpClient) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    }

    //调用首页数据
    getHome(data):Observable<any>{

        return this.http.post(AppConfig.baseUrl+"Home/index",this.getId(data));
    }

    //付款列表页
    getPyaList(data):Observable<any>{

      return this.http.post(AppConfig.baseUrl+"Mine/payment",this.getId(data));
  }
  //付款详情页
  getPyaInfo(data):Observable<any>{
   
    return this.http.post(AppConfig.baseUrl+"Approval/paymxInfo",this.getId(data));
}

  //付款状态变更
  getPyaEdit(data):Observable<any>{
   
    return this.http.post(AppConfig.baseUrl+"Mine/payEdit",this.getId(data));
  }

//根据条件更改所有的状态
getPyaEdits(data):Observable<any>{
   
  return this.http.post(AppConfig.baseUrl+"Mine/daijk",this.getId(data));
}


  

//把所有参数转换成json字符
  private getId(e){
    const body = JSON.stringify([ 
     e
  ]);
  return body;

  }
}