import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../../confi/app-config';
import {MineService} from '../../server/mine.service'
@Component({
  selector: 'app-gathering-info',
  templateUrl: './gathering-info.component.html',
  styleUrls: ['./gathering-info.component.scss']
})
export class GatheringInfoComponent implements OnInit {
  //弹出提示框内容
  public modal: any = {
    modalShow: false,
    text: '',
    btnText: [],
    yesfunc: '',
    canclefunc: '',
  }
  public searchValue:any='';
  public list:any=[];
  public name:string='';
  constructor(private toast:ToastrService, private rou: Router,private rount:ActivatedRoute,private mine:MineService  ) {
    let that=this;
    that.rount.queryParams.subscribe(res => {
      that.name=res.name
    });
    that.Payee();
   }

  ngOnInit(): void {
  }
  add() {
    let that = this;
    that.rou.navigate(['/editGatheringInfo',{id:0,name:that.name}])
  }
  detail(id){
    let that = this;
    that.rou.navigate(['/editGatheringInfo',{id:id,name:that.name}])
  }
  //删除
  del(index,id) {
    let that = this;
    that.modal.modalShow = true;
    that.modal.text = "是否删除";
    that.modal.btnText = ['取消', '确定'];
    //确认按钮
    that.modal.yesfunc = function () {
      that.mine.getPayeeDel({"id":id}).subscribe(res=>{
        if(res.success==200){
          that.toast.success(res.mes, '', { timeOut: 1000 });
         
          that.list.splice(index,1);
        }else{
          that.toast.error(res.mes, '', { timeOut: 1000 });
        }
      })
    
    }
  }

  search(e){
    //调用实际付款人
    this.Payee();
  }

  sear(){
    this.Payee();
  }

  //调用实际付款人
  Payee(){
      let that=this;
      var data={"search":that.searchValue}
      
      that.mine.getPayee(data).subscribe(res=>{
          if(res.success==200){
              that.list=res.data;
          }else{
            that.list=[];
          }
      })
  }
}
