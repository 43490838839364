import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {BorrowService} from '../../server/borrow.service'
@Component({
  selector: 'app-jkinfo',
  templateUrl: './jkinfo.component.html',
  styleUrls: ['./jkinfo.component.scss']
})
export class JkinfoComponent implements OnInit {
 public jk_bh:string='';  
  public packup:any=[true,true]    //是否收起  是true，否false
  public jk_type:string='';  //报销单状态 
  public data:any={};  //当前头部数据信息
  public sp_list:any={};
  public type_name:string='';
  public dep_name:string='';  //部门
  public create:string='';  //时间
  public name:string='';
  public id:string='';
  constructor(private toast:ToastrService, 
              private rou:Router, 
              private rount:ActivatedRoute,
              private borr:BorrowService,
              private borrow:BorrowService,
              ) { 
    let that=this;
     //接收参数报销单
     that.jk_bh=that.rount.snapshot.params['jk_bh'];
     that.jk_type=that.rount.snapshot.params['type'];
     that.id=that.rount.snapshot.params['id'];
     
    // that.headinfo();
     that.jkinfo();
    


  }

  //获取头部信息
  //获取报销单明细信息头部数据
  headinfo(){
    let that=this;
   that.borrow.getjkmxHeadinfo({"id":that.id}).subscribe(res=>{
     if(res.success==200){
       that.jk_bh=res.data[0].JKD_BH;
       that.type_name=res.data[0].TYPE_NAME;
       that.name=res.data[0].bxdinfo.USER_NAME;
      
       that.dep_name=res.data[0].bxdinfo.DEP_NAME;
       that.create=res.data[0].bxdinfo.CREATE_DATE;
     }else{
       that.toast.error(res.mes,'',{timeOut:1000});
       that.rou.navigate(['/home'])
     }
      
   })
  }


  //根据报销单查询出详情数据
  jkinfo(){
    let that=this;
    that.borr.getjkinfo({"jkd_bh":that.jk_bh}).subscribe(res=>{
      if(res.success==200){
        that.data=res.data[0]
        if(that.data.P_STATUS==0){
          that.data.P_STATUS='审批中'
        }else if(that.data.P_STATUS==1){
          that.data.P_STATUS='已通过'
        }else{
          that.data.P_STATUS='已退回'
        }
        that.data.mx.forEach(element => {
          that.packup.push(true);
        });
        //默认第一个展开
        that.packup[0]= !this.packup[0];
      }else{
        that.toast.error(res.mes,'',{timeOut:1000});
        that.rou.navigate(['/home']);
      }
     
    })
  }

  ngOnInit(): void {
  }

  //点击图片查看详情
  imginfo(id,status,mx_id,type_name){
    console.log(type_name);
    if(type_name !='收据'){
      this.rou.navigate(["/accessory",{id:id,bxdbh:this.jk_bh,status:status,mx_id:mx_id}])

    }else{
      this.rou.navigate(["/faillacc",{id:id,bxdbh:this.jk_bh,status:status,mx_id:mx_id}])

    }
  }

  tuckup(index){
    this.packup[index]= !this.packup[index];
  }

  //点击退回按钮操作
  tuihui(){
    let that=this;
    this.rou.navigate(['/loanapply',{jk_bh:that.jk_bh}]);
  }

}
