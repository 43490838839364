import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {BorrowService} from '../../server/borrow.service'
import { SignaturePad } from 'angular2-signaturepad'
import * as localForage from "localforage";
import {FlowService} from '../../server/flow.service'

@Component({
  selector: 'app-jkshenpi',
  templateUrl: './jkshenpi.component.html',
  styleUrls: ['./jkshenpi.component.scss']
})
export class JkshenpiComponent implements OnInit {

  @ViewChild(SignaturePad, {static:false}) signaturePad: SignaturePad;
  public jk_bh:string='';  
  public packup:any=[true,true]    //是否收起  是true，否false
  public jk_type:string='';  //报销单状态 
  public data:any={};  //当前头部数据信息
  public sp_list:any={};
  public type_name:string='';
  public dep_name:string='';  //部门
  public create:string='';  //时间
  public name:string='';
  public id:string='';
  public yijian:string='';
  public canshu:any={};  //接口参数
  signatureImage: string='';
  public user_id:string=''; 
  public signaturePadOptions: Object = {
    minWidth: 0.5,// 比划的粗细,
    canvasWidth: 305, // 可写宽度
    canvasHeight: 150,// 可写高度
  };


  constructor(
  
    private rou:ActivatedRoute,
    private routr:Router,
    private borrow:BorrowService,
    private toast:ToastrService,
    private flow:FlowService

  ) {
    let that=this;
    //接收参数报销单
    that.jk_bh=that.rou.snapshot.params['jk_bh'];
    that.id=that.rou.snapshot.params['id'];
    
    that.headinfo();
    that.jkinfo();

    this.signaturePadOptions={
      minWidth: 0.5,// 比划的粗细,
      canvasWidth: ((window.innerWidth*0.92)-45), // 可写宽度
      canvasHeight: ((window.innerWidth*0.92)*0.273),// 可写高度
    }

    localForage.getItem("user").then(function(value:any){
        if(value !=null){
            that.user_id=value.id
        }
    })

    
   }

  ngOnInit(): void {
  }

  drawComplete(){
    let that=this;
    this.signatureImage =this.signaturePad.toDataURL();
    
  }

  //拒绝/同意按钮
  sum(e){
    let that=this;

    if(that.signatureImage !=''){
      //调用签名入库
      that.borrow.getqm({"jkd_bh":that.jk_bh,"qz":that.signatureImage,'type':1}).subscribe(res=>{

        that.signatureImage=res.data;

        that.flow_deal(e);
      })
    }else{
      that.toast.error("请填写签名",'',{timeOut:1000})

     }  
  }

  //根据报销单查询出详情数据
  jkinfo(){
    let that=this;
    that.borrow.getjkinfo({"jkd_bh":that.jk_bh}).subscribe(res=>{
      if(res.success==200){
        that.data=res.data[0]
      
        that.data.mx.forEach(element => {
          that.packup.push(true);
        });
        //默认第一个展开
        that.packup[0]= !this.packup[0];
      }else{
        that.toast.error(res.mes,'',{timeOut:1000});
        that.routr.navigate(['/home']);
      }
     
    })
  }


  //获取头部信息
  //获取报销单明细信息头部数据
  headinfo(){
    let that=this;
   that.borrow.getjkmxHeadinfo({"id":that.id}).subscribe(res=>{
     if(res.success==200){
       that.jk_bh=res.data[0].JKD_BH;
       that.type_name=res.data[0].TYPE_NAME;
       that.name=res.data[0].bxdinfo.USER_NAME;
      
       that.dep_name=res.data[0].bxdinfo.DEP_NAME;
       that.create=res.data[0].bxdinfo.CREATE_DATE;
     }else{
       that.toast.error(res.mes,'',{timeOut:1000});
       that.routr.navigate(['/home'])
     }
      
   })
  }


  //调用审批流程
  flow_deal(e){
    let that=this;
    that.canshu.jkd_bh=that.jk_bh;
    that.canshu.status=e;
    that.canshu.content=that.yijian;
    that.canshu.user_id=that.user_id;

    that.canshu.qz=that.signatureImage;

    that.flow.getflowjk(that.canshu).subscribe(res=>{
      if(res.success==200){
        that.toast.success(res.mes,'',{timeOut:1000});
        that.routr.navigate(['/approve']);
      }else{
        that.toast.error(res.mes,'',{timeOut:1000});
      }
    })
  }


  //清除画布
  drawClear(){
    this.signaturePad.clear();
  }




  tuckup(index){
    this.packup[index]= !this.packup[index];
  }
  



}
