import { Component, OnInit,ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import {BorrowService} from '../../server/borrow.service'
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/app/confi/app-config';
import {ActivatedRoute,Router} from '@angular/router';
import { BxdService } from '../../server/bxd.service'

@Component({
  selector: 'app-loanapply',
  templateUrl: './loanapply.component.html',
  styleUrls: ['./loanapply.component.scss']
})
export class LoanapplyComponent implements OnInit {
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  public signaturePadOptions: Object = { };
  public signatureImage: any = '';

  public jk_bh:string='';
  public data:any={}; //接口参数
  public jk_info:any=[];  //头部接口数据
  public jk_type_info:any=[];  //列表数据
  public price:number=0; //总报销金额
  public payees = []; //实际收款人数组
  public payees_id: any = 0;  //实际收款人id
  public is_payes: any = '1'; //是否为实际收款人
  public is_use_payee = false  //是否显示
  public bank_card: string = ''; //实际账户
  public is_sk_zhs: any = '0';//是否修改收款帐号
  public is_sk_b = false  //是否显示修改收款帐号输入框
  public zhanghao: string = '';  //修改后的收款帐号值

    //弹出提示框内容
  public modal: any = {
    modalShow: false,
    text: '',
    btnText: [],
    yesfunc: '',
    canclefunc: '',
  }

  constructor(private borrow:BorrowService,
            private toast:ToastrService, 
            private rou:Router, 
            private rount:ActivatedRoute,
            private bxds: BxdService  
    
    ) { 
    let that=this;
    this.signaturePadOptions = {
      minWidth: 0.5,// 比划的粗细,
      canvasWidth: ((window.innerWidth * 0.92) - 45), // 可写宽度
      canvasHeight: ((window.innerWidth * 0.92) * 0.273),// 可写高度
    }

     //接收参数报销单
     that.jk_bh=that.rount.snapshot.params['jk_bh'];
     console.log(that.jk_bh);
      //调用接口方法
    that.bxdTypeList()
  }

  ngOnInit(): void {
  }
  is_sk_zh(e) {
    let that = this;
    that.is_sk_b = !that.is_sk_b;
    that.is_sk_zhs = e;
  }
  is_paye(e) {
    let that = this;
    that.is_use_payee = !that.is_use_payee;
    that.is_payes = e;
    if (e == '1') {
      that.is_sk_b = false;
      that.is_sk_zhs = '0';
      that.payees_id = 0
    }
  }
  //实际收款人信息
  payee() {
    let that = this;
    that.bxds.getPayee().subscribe(res => {
      if (res.success == 200) {
        console.log(res.data)
        that.payees = res.data;
      }
    })
  }
  set_platform(e) {

    if (this.is_payes == '1') {
      this.bank_card = '';
    } else {
      for (let i = 0; i < this.payees.length; i++) {
        if (this.payees[i].ID == e) {
          this.bank_card = this.payees[i].BANK_CARD;
          break;
        }
      }
    }
  };
  add(){
    let that=this;
    that.rou.navigate(['/loantype',{jkbh:this.jk_bh}])
  }


    //调用报销单列表
    bxdTypeList(){
      let that=this;
      that.data.jkd_bh=that.jk_bh;
      that.borrow.getjkmxType(that.data).subscribe(res=>{
        if(res.success==200){
          console.log(res);
            that.jk_info=res.data.jkd_info[0];
            for(var i=0;i<res.data.mx.length;i++){
              res.data.mx.showDel=false
            }
            that.jk_type_info=res.data.mx;
            if(res.data.price ==null){
              that.price=0;
  
            }else{
              that.price=res.data.price;
  
            }
        }else{
            that.toast.error(res.mes,'',{timeOut:1000});
            that.rou.navigate(['/home'])
        }
  
      })
      that.payee();
    }

  drawComplete() {
    let that = this;
    this.signatureImage = this.signaturePad.toDataURL();
    AppConfig.fpr_qz= this.signatureImage;
  }

   //清除画布
   drawClear(){
    this.signaturePad.clear();
    AppConfig.fpr_qz='';
  }


  //跳转至修改页面
  boedit(name,id){
    console.log(id);
  
      this.rou.navigate(['/loangeneral',{id:id,name:name,jkbh:this.jk_bh}])
    
  }

  //删除
  del(id){
    let that=this;
    that.modal.modalShow=true;
    that.modal.text="是否删除";
    that.modal.btnText = ['取消','确定'];
     //确认按钮
     that.modal.yesfunc = function(){

          that.borrow.getDel({"id":id}).subscribe(res=>{
            if(res.success==200){
              that.toast.success("删除成功",'',{timeOut:1000});
              setTimeout(function(){
                that.bxdTypeList();
              },1000)
            }else{
              that.toast.error("删除失败",'',{timeOut:1000});
            }
          })
          console.log(id);
      }
  }

  //提交数据
  suim(){
    let that=this;

    for(var i=0;i<that.jk_type_info.length; i++){
      if(that.jk_type_info[i].BX_PRICE==0){
        that.toast.error(that.jk_type_info[i].NAME+"金额不能为0",'',{timeOut:1000});
        return false
        break;
      }
    }
    //判断是否选择实际付款人
    if (that.payees_id == 0 && that.is_payes == '0') {
      that.toast.error("请选择实际付款人", '', { timeOut: 1000 });
      return false;
    }

    //判断是否选择了修改账户信息
    if (that.is_sk_zhs == '1' && that.zhanghao == '') {
      that.toast.error("请输入要修改的收款帐号信息", '', { timeOut: 1000 });
      return false;
    }

    if(that.price ==0){
        that.toast.error("总金额不能为0",'',{timeOut:1000});
    }else{

        if(AppConfig.fpr_qz !=''){
             //调用签名入库
            that.borrow.getqm({"jkd_bh":that.jk_bh,"qz":AppConfig.fpr_qz,'type':0}).subscribe(res=>{

              //调用流程接口
                that.borrow.getflow({
                  "jkd_bh":that.jk_bh,
                "zhanghao": that.zhanghao, 
                "payee_id": that.payees_id,}).subscribe(res=>{
                  if(res.success==200){
                    that.toast.success(res.mes,'',{timeOut:1000});
                    AppConfig.fpr_qz=''
                    setTimeout(function(){
                      that.rou.navigate(['/loanlist']);
                    },1100)
                  
                  }else{
                    that.toast.error(res.mes,'',{timeOut:1000});
            
                  }
                })
            })

        }else{
          that.toast.error("请补充签名",'',{timeOut:1000});

        }
    }
  }

}
