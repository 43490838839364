import { Component, OnInit } from '@angular/core';
import * as localForage from "localforage";
import {BxdService} from '../../server/bxd.service'
import { AppConfig } from 'src/app/confi/app-config';
@Component({
  selector: 'app-mylist',
  templateUrl: './mylist.component.html',
  styleUrls: ['./mylist.component.scss']
})
export class MylistComponent implements OnInit {
  public index:number=0;
  public id:any;   //登录人ID
  public listData:any=[];
  
  public types:string='未报销'

  constructor(private bxd:BxdService) {
    let that=this;
    localForage.getItem("user").then(function(value:any){

      that.id=value.id;
      that.bxdList();

    })
    that.index=AppConfig.indexs;  //调用索引
    that.types=AppConfig.indexstype;


   }

   //获取当前个人报销单数据
   bxdList(){
     let that=this;
    that.bxd.getbxList({"id":that.id,'type':that.types}).subscribe(res=>{
        that.listData=res.data
    })
   }

  ngOnInit(): void {
  }

  //点击选项卡
  type(index){
    console.log(index);
    let that=this;
    switch(index){
      case 0:
        that.types='未报销'
      break;
      case 1:
        that.types='已退回'
      break;
      case 2:
        that.types='审批中'
      break;
      default:
        that.types='已通过'
    }
    console.log(that.types);

    AppConfig.indexs=index;
    AppConfig.indexstype=that.types;
    //调用接口
    that.bxdList();
  
    this.index = index;
    
  }
}
