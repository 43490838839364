import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {LoginService} from '../../server/login.service'
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-findpwd',
  templateUrl: './findpwd.component.html',
  styleUrls: ['./findpwd.component.scss']
})
export class FindpwdComponent implements OnInit {

  public disabledClick: boolean = false;  //获取验证码按钮是否不可点击
  public btnText: string = '获取验证码';    //获取验证码按钮文字
  public second: number = 60;   //倒计时s
  public fill:boolean = false;   //输入框是否输入完全
  public codes:string=""; //获取接口返回的验证码
  public code:string="";  //获取输入的验证码
  public data:any={};  //参数数据
  public tel:string=""; //手机号
  constructor(public router:Router,private logins:LoginService,private toastr: ToastrService) { 
  }

  ngOnInit(): void {
  }

  inputt(){
    if(this.tel !='' && this.code !=''){
      this.fill=true;
    }else{
      this.fill=false;
    }
  }

  //获取验证码
  getCode() {
    let that = this;

    if(that.second==60 && that.tel !=''){
      //调用验证码接口
      that.data.tel=that.tel;
      that.data.worldcode='+86';
      that.logins.getCode(that.data).subscribe(res=>{
        if(res.success==200){
          that.codes=res.data;
        }
      })
      that.countDown()

    }else{
      this.toastr.error('手机号不能为空','', {timeOut: 1000})

    }
  }

  // 倒计时
  countDown() {
    let that = this;
    if (that.second == 0) {
      that.btnText = '获取验证码';
      that.disabledClick = false;
      that.second = 60;
    } else {
      that.disabledClick = true;
      that.btnText = that.second + 's';
      that.second--;
      setTimeout(function () {
        that.countDown()
      }, 1000)
    }
  }


  //提交
  onSubmit(){
    if((this.code==this.codes) && (this.fill==true)){
      this.router.navigate(['/pwdcomfirm',{tel:this.tel}])


    }else{
      this.toastr.error('验证码错误','', {timeOut: 1000}).onAction.subscribe(res=>{
        console.log('Toastr clicked');
      })
   }
  }

 
}
