import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {HomesService} from '../../server/homes.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pdetail',
  templateUrl: './pdetail.component.html',
  styleUrls: ['./pdetail.component.scss']
})
export class PdetailComponent implements OnInit {

  public packup:any=[true,true]    //是否收起  是true，否false
  public bxdbh:string=''
  public data:any={};
  public money:any={};

    //弹出提示框内容
    public modal: any = {
      modalShow: false,
      text: '确认是否已付款',
      btnText: [],
      yesfunc: '',
      canclefunc: '',
    }

  constructor(private rou:Router,private rout:ActivatedRoute,private home:HomesService,private toast:ToastrService) { 

    let that=this;
    that.bxdbh=that.rout.snapshot.params['bxdbh'];
    that.payinfo();
  }

  ngOnInit(): void {
  }


 //点击图片查看详情
 imginfo(id,status,mx_id,type_name){
  console.log(type_name);
  if(type_name !='收据'){
    this.rou.navigate(["/accessory",{id:id,bxdbh:this.bxdbh,status:status,mx_id:mx_id}])

  }else{
    this.rou.navigate(["/faillacc",{id:id,bxdbh:this.bxdbh,status:status,mx_id:mx_id}])

  }
}

  //调用付款详情页面
  payinfo(){
    let that=this;
    that.home.getPyaInfo({"bxdbh":that.bxdbh}).subscribe(res=>{
      that.data=res.data[0]
      that.money = res.data[0].money[0];
      console.log(that.money);

      that.data.mx.forEach(element => {
        that.packup.push(true);
    });
    //默认第一个展开
    this.packup[0]= !this.packup[0];
    })
  }


  tuckup(index){
    this.packup[index]= !this.packup[index];
  }


    //确认付款动作
    queren(e){
      let that=this;
      that.modal.modalShow=true;
      that.modal.btnText=['取消','确认']
      that.modal.yesfunc=function(){
        that.home.getPyaEdit({"bxdbh":e}).subscribe(res=>{
           
            that.toast.success("操作成功",'',{timeOut:1000})

            that.payinfo();
        })
      }
     
    }

}
