import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enumerate',
  templateUrl: './enumerate.component.html',
  styleUrls: ['./enumerate.component.scss']
})
export class EnumerateComponent implements OnInit {

  @Input()
  public contentData:any=[];

  constructor(private route:Router) { 
    
  }

  ngOnInit(): void {
  }

   //去详情页
   godetail(bxdbh,pid,id){
     console.log(this.contentData.type);
     if(this.contentData.type=='借款'){

        this.route.navigate(["/jksp",{jk_bh:bxdbh,id:id}])
     }else{
      this.route.navigate(["/examine",{bxdbh:bxdbh,pid:pid}])
     }
    
  }

}
