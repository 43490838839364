import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {ActivatedRoute,Router} from '@angular/router'
import { BxdService } from '../../server/bxd.service';
import { JkdService } from '../../server/jkd.service';
import { ToastrService } from 'ngx-toastr';
import * as localForage from "localforage";

@Component({
  selector: 'app-reviewfee',
  templateUrl: './reviewfee.component.html',
  styleUrls: ['./reviewfee.component.scss']
})
export class ReviewfeeComponent implements OnInit {

//加载中内容
public load: any = {
  modalShow: false,
  text: '加载中',
 
}
  public type: number = 0;
  public files: any = [];
  //弹出提示框内容
  public modal: any = {
    modalShow: false,
    text: '是否要上传附件',
    btnText: [],
    yesfunc: '',
    canclefunc: '',
  }

 
  //报销时间
  public bxrq:string='';
  
  public typename:string='';
  public bxd_bh:string='';
  public id:string='';
  public jkd_list=[]; //借款单评审数据
  public create:string=''; //时间
  public dep_name:string=''; //部门
  public name:string=''; //姓名
  public type_name:string=''; //报销类型
  public bx_mx_bh:string=''; //明细编号
  public uid:string=''; //当前登录人ID
  public jk_mxbh='选择单号';
  public thje:number=0; //退回金额
  public img_id:number=0; //当前图片ID
  public can_data:any={}; //最终提交参数

  public cash_price:number=0; //现金支付
  public internet_bank:number=0; //网银支付

public mxinfo:any={}; //明细信息
 
  constructor(
      private rou:Router,
      private rout:ActivatedRoute,
      private bxdi:BxdService,
      private toast:ToastrService,
      private jkdser:JkdService
      ) {
    let that=this;
    
  
    that.rout.queryParams.subscribe(res=>{
      if(res){
          that.id=res.id;
          that.bxd_bh=res.bxd_bh,
          that.typename=res.typename,
          that.bx_mx_bh=res.bh
          
      }
    })
    that.headinfo();
    that.imgInfo();
    localForage.getItem("user").then(function(value:any){
      if(value !=null){
        that.uid=value.id;
      }else{
        that.uid='14'
      }

      that.jkdping();
    })

  }
  //获取退回金额值
  thjes(e){
    this.thje=e;
  }

  //获取借款单评审单号
  jkdping(){
    let that=this;
    that.jkdser.getjkdpings({"uid":that.uid}).subscribe(res=>{
      if(res.success==200){
        that.jkd_list=res.data;
      }else{
        that.jkd_list=[];
      }
    })
  }

  //获取报销单明细信息头部数据
  headinfo(){
    let that=this;
   that.bxdi.getbxdmxHeadinfo({"id":that.id}).subscribe(res=>{
     if(res.success==200){
       that.bxd_bh=res.data[0].BXD_BH;
       that.type_name=res.data[0].TYPE_NAME;
       that.name=res.data[0].bxdinfo.USER_NAME;
      
       that.dep_name=res.data[0].bxdinfo.DEP_NAME;
       that.create=res.data[0].bxdinfo.CREATE_DATE;
     }else{
       that.toast.error(res.mes,'',{timeOut:1000});
       that.rou.navigate(['/home'])
     }
      
   })
  }

  //上传图片
  fileChange(files) {
    let that = this;
    if (files.length === 0) {
      return;
    }
    const reader = new FileReader();
    let formData: FormData = new FormData();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      var aaa:any=reader.result
      formData.append('file[]', files[0]);
      formData.append("typesss",'评审报告');
      formData.append('type', '评审报告');
      formData.append('bxd_bh', that.bxd_bh);
    
      formData.append('bxd_mx_id', that.id);

      formData.append('img_id','0' );
      that.filess(formData);

    };
  }


  filess(form){
    let that=this;
    that.load.modalShow=true;
    that.load.text='上传附件中...'
    that.bxdi.getfile(form).subscribe(res=>{
      that.img_id=res.data.id
      if(res.success==200){
         //重新加载
         that.imgInfo();
         that.load.modalShow=false;
      }
    })
  }

   //调用图片显示功能
   imgInfo(){
  
    let that=this;
    that.bxdi.getimgInfo({"id":that.id}).subscribe(res=>{
      that.files=res.data.img_data   
    })
  }

  //删除图片
  delete(index,id) {
    let that=this;

    that.modal.modalShow = true;
      that.modal.text='是否要删除'
      that.modal.btnText = ['取消','确定']
  
      that.modal.yesfunc = function(){
        that.bxdi.getDelimg({"id":id,"type":"发票","mx_id":that.id}).subscribe(res=>{
          if(res.success==200){
            this.files.splice(index, 1);

            that.toast.success(res.mes,'',{timeOut:1000});
            that.imgInfo();
          }
        })
      }
  }






  //时间
  dateChange(e){
    let date = new Date(e);
    console.log(date);
    let year = date.getFullYear();
    let month:any = date.getMonth() + 1;
      month = month < 10 ? '0' + month : month;
    let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    

    this.bxrq = year + '-' + month + '-' + d;
  }


  //获取下拉选择的评审单号值
  getChange(e){
    this.jk_mxbh=e;
    console.log(e);
    this.pinginfo();
  }

  //获取当前评审单号下的数据
  pinginfo(){
    let that=this;
    that.jkdser.getjkdpinginfo({"mx_bh":that.jk_mxbh}).subscribe(res=>{
      if(res.success==200){
        that.mxinfo=res.data[0];

      }else{
        that.mxinfo={};
      }
    })
  }

  //点击提交数据
  onsumit(){
    let that=this;
    if(that.jk_mxbh=='选择单号'){
      that.toast.error("请选择评委单号",'',{timeOut:1000});
      return false;
    }
    if(that.files.length==0){
      that.toast.error("上传评审报告",'',{timeOut:1000});
      return false;
    }
    //将参数组合
    that.can_data.bxrq=that.bxrq;  //报销日期
    that.can_data.thje=that.thje;  //退回金额
    that.can_data.bxd_bh=that.bxd_bh;  //报销单号
    that.can_data.bx_mx_bh=that.bx_mx_bh; //报销明细编号
    that.can_data.jk_mxbh=that.jk_mxbh; //借款单明细编号
    that.can_data.cash_price=that.cash_price //现金支付
    that.can_data.internet_bank=that.internet_bank //网银支付
    that.can_data.typename=that.typename;  //类型
    that.can_data.hkje=that.mxinfo.JK_PRICE  //借款金额
    //调用评审费接口
    that.jkdser.getjkdpingedit(that.can_data).subscribe(res=>{
      if(res.success==200){
        that.rou.navigate(['/noticket',{bxd_bh:that.bxd_bh}])

      }else{
        that.toast.error(res.mes,'',{timeOut:1000});
      }

    })
    return false
  }

  ngOnInit(): void {
  }

}
