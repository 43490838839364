import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../server/login.service'
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { validateRex, confirmCode } from '../../validate/validate-register';
import { AppConfig } from 'src/app/confi/app-config';
import * as localForage from "localforage";

@Component({
  selector: 'app-enrollform',
  templateUrl: './enrollform.component.html',
  styleUrls: ['./enrollform.component.scss']
})
export class EnrollformComponent implements OnInit {

  public code: string; //微信授权后的code
  public name: string; //用户名
  public heard: string; //头像
  //public openid: string; //微信信息
  public unionid: string; //微信信息unionid
  public user: any = {}; //定义缓存
  public openid: string = ''; //openid

  public disabledClick: boolean = false;  //获取验证码按钮是否不可点击
  public btnText: string = '获取验证码';    //获取验证码按钮文字
  public second: number = 60;   //倒计时s
  public secret: boolean = true;      //密码是否加密
  public fill: boolean = false;    //是否输入完全
  public formModel: FormGroup;  //表单
  public codes: string = ''; //接口返回的验证码数据
  public data: any = {};  //接口参数
  public formErrors = {
    tel: '',
    code: '',
    name: '',
    pwd: ""
  };
  // 为每一项表单验证添加说明文字
  validationMessage = {
    'tel': {
      'required': '',
      'notdown': '手机号不正确',
    },
    'pwd': {
      'required': '密码不能为空',
    },
    'code': {
      'required': '验证码不能为空',

    },
    'name': {
      'required': '姓名不能为空',

    }
  };
  constructor(
    private fb: FormBuilder,
    private logins: LoginService,
    private toastr: ToastrService,
    private router: Router,
    private routes: ActivatedRoute
  ) {



  }
  // 构建表单方法
  buildForm(): void {
    let that = this;
    this.formModel = this.fb.group({
      tel: ['', [Validators.required,
      validateRex('notdown', /^(((13[0-9]{1})|(15[0-9]{1})|(17[0-9]{1})|(18[0-9]{1}))+\d{8})$/),
      ]],
      pwd: ['', [Validators.required]],
      name: ['', [Validators.required]],
      code: ['', [Validators.required,

      ]],
    })
    // 每次表单数据发生变化的时候更新错误信息
    this.formModel.valueChanges
      .subscribe(data => this.onValueChanged(data));
  }





  // 每次数据发生改变时触发此方法
  onValueChanged(data?: any, dirty?) {
    // 如果表单不存在则返回
    if (!this.formModel) return;
    // 获取当前的表单
    const form = this.formModel;

    // 遍历错误消息对象
    for (const field in this.formErrors) {
      // 清空当前的错误消息
      this.formErrors[field] = '';
      // 获取当前表单的控件
      const control = form.get(field);

      if (dirty) {
        // 当前表单存在此空间控件 && 此控件验证不通过
        if (control && !control.valid) {
          // 获取验证不通过的控件名，为了获取更详细的不通过信息
          const messages = this.validationMessage[field];
          // 遍历当前控件的错误对象，获取到验证不通过的属性
          for (const key in control.errors) {
            // 把所有验证不通过项的说明文字拼接成错误消息
            this.formErrors[field] += messages[key] + '\n';
          }
        }
      } else {
        // 当前表单存在此空间控件 && 此控件没有被修改 && 此控件验证不通过
        if (control && control.dirty && !control.valid) {
          // 获取验证不通过的控件名，为了获取更详细的不通过信息
          const messages = this.validationMessage[field];
          // 遍历当前控件的错误对象，获取到验证不通过的属性
          for (const key in control.errors) {
            // 把所有验证不通过项的说明文字拼接成错误消息
            this.formErrors[field] += messages[key] + '\n';
          }
        }
      }


    }
  }



  ngOnInit(): void {

    this.routes.queryParamMap.subscribe((params: any) => {

      this.code = params.get('wx_user');
      if (this.code) {
        this.name = JSON.parse(this.code).nickname;
        this.heard = JSON.parse(this.code).headimgurl;
        this.openid = JSON.parse(this.code).openid;
        this.unionid = JSON.parse(this.code).unionid;
        AppConfig.user = { "openid": this.openid, "unionid": this.unionid }

        //localForage.setItem("user",AppConfig.user);

        // 初始化时构建表单
        this.buildForm();
      } else {
        console.log('跳转至微信授权');
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + AppConfig.appid + "&redirect_uri=" + AppConfig.redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=register#wechat_redirect";
      }
    });

    // 初始化时构建表单
    this.buildForm();
  }

  inputt() {

    let that = this;
    //判断验证码是否一致
    if (that.formModel.get("code").value.toString().length >= 6) {
      if (that.formModel.get("code").value != that.codes) {
        that.formErrors.code = '验证码错误';
        this.fill = false;

        return false;
      }

    }
    console.log(that.formModel.get("code").value == that.codes);
    if ((that.formModel.valid) && (that.formModel.get("code").value == that.codes)) {
      this.fill = true;
    } else {
      this.fill = false;
    }

  }
  //密码加密解密
  encrypt() {
    this.secret = !this.secret;
  }
  //获取验证码
  getCode() {
    let that = this;
    if (that.formModel.get('tel').value && that.second == 60) {
      //调用验证码接口
      that.data.tel = that.formModel.get('tel').value;
      that.data.worldcode = '+86';
      that.logins.getCode(that.data).subscribe(res => {
        if (res.success == 200) {
          this.toastr.success('验证码发送成功', '', { timeOut: 1000 })

          that.codes = res.data;
        }
      })
      that.countDown()

    } else {

      this.toastr.error('手机号不能为空', '', { timeOut: 1000 })

    }
  }

  // 倒计时
  countDown() {
    let that = this;
    if (that.second == 0) {
      that.btnText = '获取验证码';
      that.disabledClick = false;
      that.second = 60;
    } else {
      that.disabledClick = true;
      that.btnText = that.second + 's';
      that.second--;
      setTimeout(function () {
        that.countDown()
      }, 1000)
    }
  }

  onSubmit() {
    let that = this;
    let formValue = that.formModel.value;
    if ((this.fill) && (this.codes == formValue.code)) {


      //调用注册接口
      that.data.tel = formValue.tel;
      that.data.pwd = formValue.pwd;
      that.data.openid = that.openid;   //授权后替换
      that.data.name = formValue.name;
      that.data.unionid = that.unionid;  //授权后替换
      that.data.head = that.heard;  //授权后替换
      //调用注册接口
      that.logins.getregister(that.data).subscribe(res => {
        if (res.success == 200) {
          that.toastr.success(res.mes, '', { timeOut: 1000 })
          setTimeout(function () {
            that.router.navigateByUrl("/login");

          }, 1020);

        } else {
          this.toastr.error(res.mes, '', { timeOut: 1000 })

        }
      })
    } else {
      console.log(formValue);
      console.log(that.codes != formValue.code);
      that.onValueChanged('', true)

      if (that.codes != formValue.code) {
        that.formErrors.code = '验证码错误';
      }
    }
  }


}


