import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toppart',
  templateUrl: './toppart.component.html',
  styleUrls: ['./toppart.component.scss']
})
export class ToppartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
