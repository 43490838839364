import { Component, OnInit, ViewChild } from '@angular/core';
import {ApprService} from '../../server/appr.service'
import {ActivatedRoute,Router} from '@angular/router'
import { SignaturePad } from 'angular2-signaturepad'
import {BxdService} from '../../server/bxd.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-examine',
  templateUrl: './examine.component.html',
  styleUrls: ['./examine.component.scss']
})
export class ExamineComponent implements OnInit {

  @ViewChild(SignaturePad, {static:false}) signaturePad: SignaturePad;

  public packup:any=[]    //是否收起  是true，否false
  public bxdbh:string="";
  public pid:string=''; //审批节点ID
  public data:any;  //数据集
  public name:string=''; //报销人
  public dep_name:string=''; //部门
  public create_date:string=''; //日期
  public reviewed_name:string='';  //审批人
  public bxr_qz:string=''; //报销人签字
  public status:string=''; //状态
  public actual_qz:string=''; //审批人签字
  public text:string='';
  public yijian:string=''; //审批意见
 public user_id:string=''; //审批人ID
  public canshu:any={}; //参数值
  public alldata:any; // 所有数据
public payee:any;  //实际收款人  
  signatureImage: string='';
  public signaturePadOptions: Object = {
    minWidth: 0.5,// 比划的粗细,
    canvasWidth: 305, // 可写宽度
    canvasHeight: 150,// 可写高度
  };

    //弹出提示框内容
public modal: any = {
  modalShow: false,
  text: '',
  btnText: [],
  yesfunc: '',
  canclefunc: '',
}


  constructor(
    private appr:ApprService,
    private rou:ActivatedRoute,
    private routr:Router,
    private bxds:BxdService,
    private toast:ToastrService

  ) {
    let that=this;
    that.bxdbh=that.rou.snapshot.params["bxdbh"];
    that.pid=that.rou.snapshot.params['pid'];

    this.signaturePadOptions={
      minWidth: 0.5,// 比划的粗细,
      canvasWidth: ((window.innerWidth*0.92)-45), // 可写宽度
      canvasHeight: ((window.innerWidth*0.92)*0.273),// 可写高度
    }

    that.apprinfo()
   }

  ngOnInit(): void {

    this.payee={};
  }

  drawComplete(){
    let that=this;
    this.signatureImage =this.signaturePad.toDataURL();
    
  }

  //拒绝/同意按钮
  sum(e){
    let that=this;

    if(that.signatureImage !=''){
      //调用签名入库
      that.bxds.getqm({"bxd_bh":that.bxdbh,"qz":that.signatureImage,'type':1}).subscribe(res=>{

        that.signatureImage=res.data;

        that.flow_deal(e);
      })
    }else{
      that.toast.error("请填写签名",'',{timeOut:1000})

    }  
  }

  //调用审批流程
  flow_deal(e){

    let that=this;

    that.canshu.bxd_bh=that.bxdbh;
    that.canshu.status=e;
    that.canshu.content=that.yijian;
    that.canshu.user_id=that.user_id;
    that.canshu.qz=that.signatureImage;
    if(e==1){
      that.text='是否同意审批';
    }else{
      that.text='是否拒绝审批';
    }
    that.modal.modalShow=true;
    that.modal.text=that.text;
    that.modal.btnText=['取消','确认'];
    that.modal.yesfunc=function(){
      that.appr.getflow(that.canshu).subscribe(res=>{
        if(res.success==200){
          that.toast.success(res.mes,'',{timeOut:1000});
          that.routr.navigate(['/approve']);
        }else{
          that.toast.error(res.mes,'',{timeOut:1000});
        }
      })
    }
    
  }


  //清除画布
  drawClear(){
    this.signaturePad.clear();
  }

 //调用审批详情接口
 apprinfo(){
  let that=this;
  that.appr.getbxdmxInfo({"bxdbh":that.bxdbh,"pid":that.pid}).subscribe(res=>{
      if(res.success==200){
          that.user_id=res.data[0].USER_ID;
          that.name=res.data[0].USER_NAME;
          that.dep_name=res.data[0].DEP_NAME;
          that.create_date=res.data[0].CREATE_DATE;
          that.reviewed_name=res.data[0].REVIEWED_NAME;
          that.bxr_qz=res.data[0].BXR_QZ
          that.status=res.data[0].P_STATUS
          that.actual_qz=res.data[0].ACTUAL_QZ;
          that.data=res.data[0].mx; //列表数据
          that.payee=res.data[0].PAYEE
          that.data.forEach(element => {
              this.packup.push(true);
          });
          that.alldata = res.data[0];
          this.packup[0]= !this.packup[0];
      }
      console.log(res);
  })
}

  //点击图片查看详情
  imginfo(id,mx_id){
    
    this.routr.navigate(["/accessory",{id:id,mx_id:mx_id,bxdbh:this.bxdbh}])
  }


  tuckup(index){
    this.packup[index]= !this.packup[index];
  }
  



}
