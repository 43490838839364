import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ToppartComponent } from './login/toppart/toppart.component';
import { LoginfromComponent } from './login/loginfrom/loginfrom.component';
import { EnrollformComponent } from './login/enrollform/enrollform.component';
import { FindpwdComponent } from './login/findpwd/findpwd.component';
import { PwdcomfirmComponent } from './login/pwdcomfirm/pwdcomfirm.component';
import { HomeComponent } from './home/home.component';
import { TabbarComponent } from './tabbar/tabbar.component';
import { ChoosetypeComponent } from './reimburseprocess/choosetype/choosetype.component';
import { NoticketComponent } from './reimburseprocess/noticket/noticket.component';
import { FilloutComponent } from './reimburseprocess/fillout/fillout.component';
import { GeneralfilloutComponent } from './reimburseprocess/generalfillout/generalfillout.component';
import { ReviewfeeComponent } from './reimburseprocess/reviewfee/reviewfee.component';
import { AccessoryComponent } from './reimburseprocess/accessory/accessory.component';
import { MylistComponent } from './myreimbursement/mylist/mylist.component';
import { SearchComponent } from './myreimbursement/search/search.component';
import { DetailComponent } from './reimbursedetail/detail/detail.component';
import { ApproveComponent } from "./approve/approve/approve.component";
import { HistoryComponent } from './approve/history/history.component';
import { IntroComponent } from "./approve/intro/intro.component";
import { ExamineComponent } from "./approve/examine/examine.component";
import { MessageComponent } from "./message/message/message.component";
import { RecordComponent } from './payment/record/record.component'

import { PdetailComponent } from './payment/pdetail/pdetail.component'

import { FaillaccessorComponent } from './reimburseprocess/fillaccessor/fillaccessor.component'

import { MineComponent } from './mine/mine/mine.component'
import { AccountComponent } from './mine/account/account.component'
import { PreviewComponent } from './reimburseprocess/preview/preview.component';
import { PsearchComponent } from './payment/psearch/psearch.component';
import { LoanComponent } from './reimburseprocess/loan/loan.component';
import { LoantypeComponent } from './reimburseprocess/loantype/loantype.component';
import { LoanlistComponent } from './reimburseprocess/loanlist/loanlist.component';
import { LoanapplyComponent } from './reimburseprocess/loanapply/loanapply.component';
import { LoangeneralComponent } from './reimburseprocess/loangeneral/loangeneral.component';

import { FocusGuard } from './confi/focusGuard'; // 当缓存不存在跳转至登录页
import { Login } from './confi/login'; //当缓存存在直接跳转至首页
import { Pay } from './confi/pay'; //当缓存存在直接跳转至首页
import { JkinfoComponent } from './reimburseprocess/jkinfo/jkinfo.component';
import { JkshenpiComponent } from './reimburseprocess/jkshenpi/jkshenpi.component';
import { JksearchComponent } from './reimburseprocess/jksearch/jksearch.component';
import { JkdetailComponent } from './payment/jkdetail/jkdetail.component';
import { JkorderinfoComponent } from './reimburseprocess/jkorderinfo/jkorderinfo.component';

import { PdfAffixComponent } from './reimburseprocess/pdf-affix/pdf-affix.component';
import { GatheringInfoComponent } from "./reimburseprocess/gathering-info/gathering-info.component";
import { EditGatheringInfoComponent } from "./reimburseprocess/edit-gathering-info/edit-gathering-info.component";
const routes: Routes = [
  {
    path: '',
    component: ToppartComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginfromComponent,
       canActivate: [Login], // 路由拦截
        data: {
          title: '登录'
        }
      },
      {
        path: 'enroll',
        component: EnrollformComponent,
        data: {
          title: '注册'
        }
      }

    ]
  },

  

  {
    path: 'findpwd',
    component: FindpwdComponent,
    data: {
      title: '找回密码'
    }
  },
  {
    path: 'pwdcomfirm',
    component: PwdcomfirmComponent,
    data: {
      title: '再次确认密码'
    }
  },
  {
    path: 'choosetype',
    component: ChoosetypeComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '选择报销类型'
    }
  },
  {
    path: 'noticket',
    component: NoticketComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '无票报销'
    }
  },
  {
    path: 'fillout',
    component: FilloutComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '无票报销填写'
    }
  },
  {
    path: 'generalfillout',
    component: GeneralfilloutComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '普通报销填写'
    }
  },


  {
    path: 'reviewfee',
    component: ReviewfeeComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '评审费'
    }
  },
  {
    path: 'accessory',
    component: AccessoryComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '发票附件'
    }
  },
  {
    path: 'pdfAffix',
    component: PdfAffixComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '发票及附件'
    }
  },

  {
    path: 'faillacc',
    component: FaillaccessorComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '附件详情'
    }
  },

  {
    path: 'home',
    component: TabbarComponent,
   canActivate: [FocusGuard], // 路由拦截
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent,
        data: {
          title: '首页'
        }
      },
      {
        path: 'message',
        component: MessageComponent,
        data: {
          title: '消息'
        }
      },
      {

        path: 'mine',
        component: MineComponent,
        data: {
          title: '个人中心'
        }


      }

    ]
  },
  {
    path: 'mylist',
    component: MylistComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '我的报销'
    }
  },
  {
    path: 'search',
    component: SearchComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '我的报销搜索'
    }
  },
  {
    path: 'detail',
    component: DetailComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '报销详情'
    }
  },
  {
    path: 'approve',
    component: ApproveComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '审批列表'
    }
  },
  {
    path: 'history',
    component: HistoryComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '审批历史'
    }
  },
  {
    path: 'intro',
    component: IntroComponent,
   canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '审批详情'
    }
  },
  {
    path: 'examine',
    component: ExamineComponent,
    canActivate: [FocusGuard], // 路由拦截
    data: {
      title: '审批'
    }
  },

  {
    path: 'paymember',

   canActivate: [FocusGuard, Pay], // 路由拦截
    children: [{
      path: '',
      component: RecordComponent,
      data: {
        title: '付款页面'
      }
    },
    {
      path: 'pdetail',
      component: PdetailComponent,
      data: {
        title: '付款报销详情'
      }
    },
    {
      path: 'jkdetail',
      component: JkdetailComponent,
      data: {
        title: '付款借款详情'
      }
    }

    ]

  },

  {
    path: 'mine/accout',
    component: AccountComponent,
   canActivate: [FocusGuard], // 路由拦截

    data: {
      title: '个人详情'
    }
  },
  {
    path: 'preview',
    component: PreviewComponent,
    data: {
      title: '图片上传预览'
    }
  },
  {
    path: 'psearch',
    component: PsearchComponent,
    data: {
      title: '付款搜索'
    }
  },
  {
    path: 'loan',
    component: LoanComponent,
    data: {
      title: '多个借款'
    }
  },
  {
    path: 'loantype',
    component: LoantypeComponent,
    data: {
      title: '借款类型选择'
    }
  },
  {
    path: 'loanlist',
    component: LoanlistComponent,
    data: {
      title: '我的借款列表'
    }
  },
  {
    path: 'loanapply',
    component: LoanapplyComponent,
    data: {
      title: '借款申请'
    }
  },
  {
    path: 'loangeneral',
    component: LoangeneralComponent,
    data: {
      title: '借款申请-普通借款'
    }
  },
  {
    path: 'jksearch',
    component: JksearchComponent,
    data: {
      title: '借款搜索'
    }

  },

  {
    path: 'jkinfo',
    component: JkinfoComponent,
    data: {
      title: '借款详情页'
    }

  },
  {
    path: 'jkorderinfo',
    component: JkorderinfoComponent,
    data: {
      title: '借款单详情页'
    }

  },
  {
    path: 'jksp',
    component: JkshenpiComponent,
    data: {
      title: '借款审批'
    }
  },
  {
    path: 'gatheringInfo',
    component: GatheringInfoComponent,
    data: {
      title: '实际收款信息'
    }
  },
  {
    path: 'editGatheringInfo',
    component: EditGatheringInfoComponent,
    data: {
      title: '添加/修改实际收款信息'
    }
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [FocusGuard, Login, Pay] // 这里！！！！！！！
})
export class AppRoutingModule { }
