import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router'
import {MineService} from '../../server/mine.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-gathering-info',
  templateUrl: './edit-gathering-info.component.html',
  styleUrls: ['./edit-gathering-info.component.scss']
})
export class EditGatheringInfoComponent implements OnInit {

  public id:number=0
  public admin:string='';  //创建人
  public myDate = new Date();  //当前时间
  public date:string=''; //当前时间 
  public name:string='';  //收款人
  public credi_code:string='';  //信用代码
  public corporate_name:string=''; //公司名称
  public mobile:string='';  //手机号
  public bank:string='';  //开户行
  public bank_card:string=''; //卡号 
  constructor(
    private rou:ActivatedRoute,
    private routr:Router,
    private mine:MineService,
    private toast:ToastrService,
  ) { 
    let that=this;
    that.id=that.rou.snapshot.params["id"];
    that.admin=that.rou.snapshot.params['name'];
    
    var year = that.myDate.getFullYear();        //年 ,从 Date 对象以四位数字返回年份
			var month:any = that.myDate.getMonth() + 1;      //月 ,从 Date 对象返回月份 (0 ~ 11) ,date.getMonth()比实际月份少 1 个月
			var day:any = that.myDate.getDate();             //日 ,从 Date 对象返回一个月中的某一天 (1 ~ 31)
    //修改月份格式
			if (month >= 1 && month <= 9) {
        month = "0" + month;
        }
  
  //修改日期格式
  if (day >= 0 && day <= 9) {
        day = "0" + day;
        }
   that.date=year + "-" + month + "-" + day     
    that.PayeeInfo();
  }

  ngOnInit(): void {
  }

  //获取详情数据
  PayeeInfo(){
    let that=this;
    var data={"id":that.id}
    that.mine.getPayeeInfo(data).subscribe(res=>{
      if(res.success==200){
          if(res.data.length){
            that.admin=res.data[0].ADMIN,
            that.name=res.data[0].NAME;
            that.bank_card=res.data[0].BANK_CARD;
            that.bank=res.data[0].BANK;
            that.mobile=res.data[0].MOBILE;
          }else{

          }
      }else{
        that.toast.error("请求错误", '', { timeOut: 1000 });
      }
    })
  }

  //提交数据
  sub(){
    if(this.id !=0){
      this.PayUpdate();
    }else{
      this.PayCreate();
    }
  }

//修改收款人信息
PayUpdate(){
  let that=this;
  var data={"id":that.id,"name":that.name,"corporate_name":that.name,"mobile":that.mobile,"bank":that.bank,"bank_card":that.bank_card}
  that.mine.getPayUpdate(data).subscribe(res=>{
    if(res.success==200){
      that.toast.success(res.mes, '', { timeOut: 1000 });
      setTimeout(function(){
        that.routr.navigate(['/gatheringInfo'],{
          queryParams:{
            name:that.rou.snapshot.params['name']
          }
        })
      },1500)

    }else{
      that.toast.error(res.mes, '', { timeOut: 1000 });
    }
  })
}

  //添加收款人信息
  PayCreate(){
    let that=this;
    if(that.name==''){
      that.toast.error("收款人不可为空", '', { timeOut: 1000 });
      return 
    }
    // if(that.mobile==''){
    //   that.toast.error("手机号不可为空", '', { timeOut: 1000 });
    //   return 
    // }
    if(that.bank==''){
      that.toast.error("开户行不可为空", '', { timeOut: 1000 });
      return 
    }
    if(that.bank_card==''){
      that.toast.error("卡号不可为空", '', { timeOut: 1000 });
      return 
    }
    var data={"name":that.name,"corporate_name":that.name,"mobile":that.mobile,"bank":that.bank,"bank_card":that.bank_card,"admin":that.admin}
    that.mine.getPayeeCreate(data).subscribe(res=>{
      if(res.success==200){
        that.toast.success(res.mes, '', { timeOut: 1000 });
        setTimeout(function(){
          that.routr.navigate(['/gatheringInfo'],{
            queryParams:{
              name:that.rou.snapshot.params['name']
            }
          })
        },1500)
        
      }else{
        that.toast.error(res.mes, '', { timeOut: 1000 });
      }
    })
  }

}
