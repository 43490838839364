//服务必引入injectable
import {Injectable} from '@angular/core';

//自定义配置文件
import {AppConfig} from "../confi/app-config";


//obj对象
import { Observable } from 'rxjs';

import { HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
@Injectable()

export class BorrowService {

    httpOptions:any;
    
    constructor(private http: HttpClient) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    }
//把所有参数转换成json字符
  private getId(e){
        const body = JSON.stringify([ 
        e
        ]);
    return body;

  }

  //调用审批列表
  getBorrowType(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/jkType",data);
 
  }

  //获取借款单号
  getborrindex(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/index",this.getId(data));
 
  }


  //查询当前借款单号状态
  getjkdStu(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/jkdStu",this.getId(data));
 
  }

  //当借款单添加至表中
  getjkmxAdd(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/jkdmxAdd",this.getId(data));
 
  }

  //调用当前借款单类型数据
  getjkmxType(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/jkdmxType",this.getId(data));
 
  }


  //调用头部信息
  getjkmxHeadinfo(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/mxTypeInfo",this.getId(data));
 
  }

  //当前编辑详情数据
  getjkmx(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/jkdmxEdit",this.getId(data));
 
  }


  getjkdmxAdds(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/jkdmxAdds",this.getId(data));
 
  }

  //签名
  getqm(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/qmfile",this.getId(data));
 
  }

  //调用启动流程
  getflow(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"flow/jk_flow",this.getId(data));
 
  }

  //删除类别数据
  getDel(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/jkdmxDel",this.getId(data));
 
  }

  //借款详情页面
  getjkinfo(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/jkdmxInfo",this.getId(data));
 
  }

  //调用当前借款单号下的明细类别名称
  getjktypemc(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/jkd_mx_type",this.getId(data));
 
  }

  //删除当前借款单号数据
  getdeljkd(data):Observable<any>
  {
     return this.http.post(AppConfig.baseUrl+"Borrow/del_jkd",this.getId(data));
 
  }

  //将状态改变为已付款
  getPyaEdit(data):Observable<any>
  {
     
     return this.http.post(AppConfig.baseUrl+"Borrow/payEdit",this.getId(data));
 
  }

  

}