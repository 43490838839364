import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router'
import {BxdService} from '../../server/bxd.service'
import {ToastrService} from 'ngx-toastr'
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @Input()
  public contentData:any = [];
//弹出提示框内容
public modal: any = {
  modalShow: false,
  text: '是否要删除',
  btnText: [],
  yesfunc: '',
  canclefunc: '',
}
  constructor(private ro:Router,private bxds:BxdService,private toast:ToastrService) { 
  }

  ngOnInit(): void {
  }

  //点击进入详情
  baox(status,bxd){
    console.log(status);
    if(status=='未报销'){
      this.ro.navigate(['/noticket',{bxd_bh:bxd}]);
    }
  }

  //点击进入详情页
  deil(bxd,status){
    if(status=='未报销'){
      this.ro.navigate(['/noticket',{bxd_bh:bxd}]);
    }else{
      this.ro.navigate(['/detail',{bxd_bh:bxd,type:status}]);

    }
  }

  delete(index,id,status){
    let that=this;
    if(status=='未报销' || status=='已退回'){
      that.modal.modalShow=true;
      that.modal.text='是否要删除?';
      that.modal.btnText=['取消','确认'];

      that.modal.yesfunc=function(){
        that.bxds.getDelBxd({"bxd_bh":id}).subscribe(res=>{
          if(res.success==200){
              that.contentData.splice(index,1);
          }else{
            that.toast.error('删除失败','',{timeOut:1000})

          }
        })
      }
     
    }else{
      that.toast.error("只可删除未报销、已退回单据",'',{timeOut:1000})
    }
   
  }

}
