import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TouchSlideDirective } from './touch-slide.directive';

@NgModule({
    imports:[
        CommonModule
    ],
    declarations:[TouchSlideDirective],
    exports:[TouchSlideDirective]
})


export class TouchSlideModule{

}