import { Directive, HostListener, Renderer2, ElementRef, Output, EventEmitter } from "@angular/core";

@Directive({
    selector: '[appTouchSlide]'
})


export class TouchSlideDirective {
    public startX: any;   //滑动开始位置
    public endX: any;    //滑动结束位置

    constructor(
        private rd2: Renderer2,
        private el: ElementRef
    ) { }


    //滑动
    @HostListener('touchstart')
    dealwith1() {
        this.startX = event['targetTouches'][0].clientX
    }
    @HostListener('touchend')
    dealwith2() {
        this.endX = event['changedTouches'][0].clientX;
        var node = this.rd2.parentNode(this.el.nativeElement);
        if (this.endX > this.startX) {
            //右滑
            this.rd2.removeClass(node, 'slide')
        } else if (this.endX < this.startX) {
            //左滑
            this.rd2.addClass(node, 'slide')
        }
    }

}