import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {LoginService} from '../../server/login.service'
import { ToastrService } from 'ngx-toastr';
import * as localForage from "localforage";
import { AppConfig } from 'src/app/confi/app-config';

@Component({
  selector: 'app-pwdcomfirm',
  templateUrl: './pwdcomfirm.component.html',
  styleUrls: ['./pwdcomfirm.component.scss']
})
export class PwdcomfirmComponent implements OnInit {

  public secret:any[] = [true,true];     //密码是否加密
  public fill:boolean = false;   //输入框是否输入完全
  public tel:string;  //手机号
  public pwd:string='';  //密码
  public qpwd:string=''; //确认密码

  public data:any={}; //接口参数
  constructor(
    private router:Router,              
    private route:ActivatedRoute,
    private logins:LoginService,
    private toastr: ToastrService
  ) { 
    let that=this;
     //跳转过来的参数手机号
     this.tel = this.route.snapshot.params['tel'];
     console.log(this.tel);
    
  }


  //判断是否输入完整
  inputs(){
    if(this.pwd !='' && this.qpwd !=''){
        this.fill=true
    }else{
      this.fill=false;
    }
  }
  ngOnInit(): void {
  }

  //密码加密解密
  encrypt(index:number){
    this.secret[index] = !this.secret[index];
  }

  //提交修改密码操作
  onsubmit(){
    let that=this;
    if(this.fill==true && this.pwd==this.qpwd){
      
      //调用修改密码接口
      that.data.tel=that.tel;
      that.data.pwd=that.pwd;
      that.data.openid=AppConfig.user.openid;  //最后换成授权后的openid
      //调用修改接口
      that.logins.getpwdZh(that.data).subscribe(res=>{
        if(res.success==200){
          this.toastr.success('修改成功','', {timeOut: 1000});
          this.router.navigateByUrl("/login");
        }else{
          this.toastr.error(res.mes,'', {timeOut: 1000});

        }
      })
    }else{

      this.toastr.error('2次密码不一致','', {timeOut: 1000});

    }
    console.log(this.pwd);
    return false;
  }
}
