import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-historylist',
  templateUrl: './historylist.component.html',
  styleUrls: ['./historylist.component.scss']
})
export class HistorylistComponent implements OnInit {

  @Input()
  public contentData:any=[];

  constructor(private route:Router) { }

  ngOnInit(): void {
  }

  //去详情页
  godetail(bxdbh,pid){
    console.log();
    if(this.contentData.types=='普通'){
      this.route.navigate(["/intro",{bxdbh:bxdbh,pid:pid}])

    }else{
      this.route.navigate(["/jkinfo",{jk_bh:bxdbh,pid:pid}])
    }
  }

}
