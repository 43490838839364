import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import Swiper from "swiper";
import { ToastrService } from 'ngx-toastr';
import * as localForage from "localforage";

import { BxdService } from '../../server/bxd.service'
@Component({
  selector: 'app-accessory',
  templateUrl: './accessory.component.html',
  styleUrls: ['./accessory.component.scss']
})
export class AccessoryComponent implements OnInit {
  title = 'swiper01';
  public id: string = '';   //图片ID
  public bxdbh: string;  //报销单编号
  public data: any = [];  //附件数据
  public img_url: string = '';  //图片地址
  public bxd_status: string = "";   //报销单状态
  public activeIndex = 0 //设置滑动索引
  public imgLists: any = []
  public bxr_id: number = 0;  //报销人ID
  public user_id: number; //当前登录人iD
  public mx_id: number;
  public stauts: string = '';
  public imgfang: string = '';  //放大地址
  public isdb: any;
  public prices: number = 0;   //退回金额
  public remarks: string = ''; //退回原因
  public stautss: number = 0; //退回的状态
  public jsondata: any = {}; //提交修改的参数
  public modal: any = {
    modalShow: false,
    text: '是否确认删除',
    btnText: ['取消', '确认'],
    yesfunc: ''
  }

  public showMask: any = false;   //查看附件


  constructor(private router: ActivatedRoute, private bxds: BxdService, private toastr: ToastrService) {
    let that = this;
    console.log(this.showMask);
    localForage.getItem("user").then(function (value: any) {
      if (value != null) {
        that.user_id = value.id;

      }
    })

    that.id = that.router.snapshot.params['id']

    that.bxdbh = that.router.snapshot.params['bxdbh'];
    that.mx_id = that.router.snapshot.params['mx_id'];

    that.stauts = that.router.snapshot.params['stauts'];

    that.imgList();

  }

  //调用所有发票数据
  imgList() {
    let that = this;
    that.bxds.getimglist({ "mx_id": that.mx_id }).subscribe(res => {

      if (that.id == '') {
        that.id = res.data[0].ID

      } else {
      }

      that.imgLists = res.data;


      //获取索引值
      let index = that.imgLists.findIndex(
        element => element.ID === that.id);

      that.activeIndex = index;

      that.imginfo();


    })
  }
  //获取输入退回的金额
  Inputprices(e) {
    this.prices = e;
    console.log(e);
  }

  //获取退回的原因
  Inputremark(e) {
    this.remarks = e;
    console.log(e);
  }

  //点击提交操作
  editmx() {
    let that = this;
    that.jsondata.id = that.id;
    that.jsondata.price = that.prices;
    that.jsondata.status = that.stautss;
    that.jsondata.remark = that.remarks;
    if (that.prices == 0) {
      that.toastr.error("请输入退回金额", '', { timeOut: 1000 });
      return false;
    }

    if (that.remarks == '') {
      that.toastr.error("请输入退回原因", '', { timeOut: 1000 });
      return false;
    }

    //调用退回接口
    that.bxds.getbxdfp(that.jsondata).subscribe(res => {
      if (res.success == 200) {
        that.toastr.success(res.mes, '', { timeOut: 1000 });
      } else {
        that.toastr.error(res.mes, '', { timeOut: 1000 });

      }
    })
    return false;
  };


  //删除
  del(e, types) {
    let that = this;
    that.isdb = false;
    console.log(that.user_id == that.bxr_id);

    //当报销人,跟登录人一致则可删除
    if (that.user_id == that.bxr_id) {
      if (that.bxd_status == '未报销' || that.bxd_status == '已退回') {

        setTimeout(function () {

          if (that.isdb != true) {
            //  // console.log(index)
            that.modal.modalShow = true;
            that.modal.yesfunc = function () {
              //调用删除附件功能
              that.bxds.getDelimg({ id: e, type: types, mx_id: that.mx_id }).subscribe(res => {
                if (res.success == 200) {
                  that.toastr.success(res.mes, '', { timeOut: 1000 });

                  that.imgList();
                } else {
                  that.toastr.error(res.mes, '', { timeOut: 1000 })

                }
              })
            }
          }
        }, 1000)

      }

    }


  }

  //上传附件
  fileChanges(file) {

    let that = this;

    console.log(file);
    if (file.length === 0) {
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file[0]) // input.files[0]为第一个文件
    reader.onload = () => {

      let formData: FormData = new FormData();
      let a: any = reader.result;
      formData.append('file[]', file[0]);

      formData.append('type', '附件');

      formData.append('bxd_bh', that.bxdbh);

      formData.append('bxd_mx_id', '0');

      formData.append('img_id', that.id);
      //上传附件
      that.filess(formData);
    }

  }

  //上传接口
  filess(form) {

    let that = this;
    that.bxds.getfile(form).subscribe(res => {
      that.toastr.success('上传成功', '', { timeOut: 1500 });
      that.imginfo();
      // that.initSwiper();
    })
  }

  //点击加号进行上传
  upload() {
    //触发上传附件功能
    let that = this;
    if (that.bxr_id == that.user_id) {
      var oInput = document.getElementById("enc");

      oInput.click();
    }

  }

  //获取发票详情接口
  imginfo() {
    let that = this;
    that.bxds.getimginfo({ "id": that.id, "bxdbh": that.bxdbh }).subscribe(res => {
      if (res.success == 200) {
        that.img_url = res.data.data[0].IMG_S_URL
        that.data = res.data.data[0].ENC;
        that.bxd_status = res.data.staut_bxd[0].STATUS,
          that.bxr_id = res.data.staut_bxd[0].BXR_ID;
        that.prices = res.data.data[0].PRICE
      } else {
        that.toastr.error(res.mes, '', { timeOut: 1000 });
      }

      //that.initSwiper();
    })
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.initSwiper();
    }, 200);
  }


  initSwiper() {

    let that = this;
    console.log(that.activeIndex)
    const swiper = new Swiper('.swiper-container', {
      slidesPerView: 2.4,
      spaceBetween: 10,
      observer: true,
      observeParents: true


    });
    const ticket = new Swiper('.ticket', {
      initialSlide: that.activeIndex,
      spaceBetween: 30,
      observer: true,
      observeParents: true,
      on: {
        slideChangeTransitionStart: function () {
          that.activeIndex = this.activeIndex;
          //   //根据索引替换发票ID以及对应的附件数据

          that.id = that.imgLists[this.activeIndex].ID;

          that.imginfo();


        }
      },
    });



  }

  zoomimg() {
    const picture = new Swiper('.picContainer', {
      zoom: true,
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
    })
  }



  //弹窗关闭
  close() {
    this.isdb = false;
    this.showMask = false;
  }


  //放大
  fang(e) {
    // console.log("bbb");
    this.isdb = true;
    this.showMask = true;
    this.imgfang = e
    var that = this;
    setTimeout(function () {
      that.zoomimg()
    }, 50)
  }


}
