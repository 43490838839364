import { Component, OnInit } from '@angular/core';
import {ApprService} from '../../server/appr.service'
import * as localForage from "localforage";
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  
  public types:string='普通'; //一级菜单
  public typess:string='全部'; //二级菜单
  public user_id:string; //登录ID


  public index:number = 0;   //一级菜单
  public key : number = 0;//二级菜单
  public listData:any=[];

  constructor(private appr:ApprService) { 
    let that=this;
    localForage.getItem("user").then(function(value:any){
      if(value !=null){
          that.user_id=value.id;

          that.apprHistory();
      }
    })

  }


  //查询历史数据
  apprHistory(){
    let that=this;
    that.appr.getHistory({"id":that.user_id,"type":that.types,"types":that.typess}).subscribe(res=>{
      that.listData=res.data
      that.listData.types=this.types;
      console.log(res);
    })
  }

  ngOnInit(): void {
  }


  type(index){
     if(index==1){
      this.types='借款'
    }else{
      this.types='普通'
    }
    this.index=index;

    this.apprHistory();
  }

  classify(index){
    if(index==0){
      this.typess='全部'
    }else if(index==1){
      this.typess='已退回'
    }else{
      this.typess='已通过'
    }
    this.key=index
    this.apprHistory();
  }

}
