import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router'
import {BorrowService} from '../../server/borrow.service'
import {ToastrService} from 'ngx-toastr'
import { AppConfig } from 'src/app/confi/app-config';
@Component({
  selector: 'app-loangeneral',
  templateUrl: './loangeneral.component.html',
  styleUrls: ['./loangeneral.component.scss']
})
export class LoangeneralComponent implements OnInit {

  //多选框
  public mask: any = {
    showMask: false,
    content: [],
    checked: [],
    isSelect: []
  };

  //表单
  //时间
  public time: string = '';

  public id:number=0;  //ID号
  public name:string='';  //类型名称
  public jkbh:string='';  //借款卡号
  public type_name:string='';
  public dep_name:string='';  //部门
  public create:string='';  //时间

  public jkdw:string='';  //评审单位
  public jkprice:string='';  //借款金额
  public jkxm:string='';  //申请项目
  public jkyt:string='';  //借款用途
  public cash:string='';  //现金支付
  public intback:string='';  //网银支付
  public hkrq:string='';  //还款时间
 public status:string='';  //当前状态
 public data:any={};
  constructor(private rou:Router,private router:ActivatedRoute,private borrow:BorrowService,private toast:ToastrService) {
    let that=this;
    that.id=that.router.snapshot.params['id'];
    that.type_name=that.router.snapshot.params['name'];
    that.jkbh=that.router.snapshot.params['jkbh'];
    that.headinfo();
    that.getBXmx();
    that.bxdStus();
  }


   //查询当前报销单状态
   bxdStus(){
    let that=this;
    that.borrow.getjkdStu({"jkd_bh":that.jkbh}).subscribe(res=>{
      that.status=res.data
    })
  }

  //获取报销单明细信息头部数据
  headinfo(){
    let that=this;
   that.borrow.getjkmxHeadinfo({"id":that.id}).subscribe(res=>{
     if(res.success==200){
       that.jkbh=res.data[0].JKD_BH;
       that.type_name=res.data[0].TYPE_NAME;
       that.name=res.data[0].bxdinfo.USER_NAME;
      
       that.dep_name=res.data[0].bxdinfo.DEP_NAME;
       that.create=res.data[0].bxdinfo.CREATE_DATE;
     }else{
       that.toast.error(res.mes,'',{timeOut:1000});
       that.rou.navigate(['/home'])
     }
      
   })
  }


   //获取当前报销明细数据是否有值
   getBXmx(){
    let that=this;
    that.borrow.getjkmx({"id":that.id}).subscribe(res=>{
      if(res.success==200){

        //报销总金额
        if(res.data[0].BX_PRICE==0){
          that.jkprice='';
        }else{
          that.jkprice=res.data[0].JK_PRICE;
        }
        
        //网银支付
        if(res.data[0].INTBACK==0){
          that.intback='';
        }else{
          that.intback=res.data[0].INTBACK;
        }

        //现金支付
        if(res.data[0].CASH==0){
          that.cash='';

        }else{
          that.cash=res.data[0].CASH;

        }
        that.jkdw=res.data[0].JKDW;
        that.jkyt=res.data[0].JKTY;
        that.jkxm=res.data[0].JKXM;
        that.jkyt=res.data[0].JKYT;
        that.hkrq=res.data[0].HKRQ;
      }else{
        that.jkdw=AppConfig.jkdw;
        that.jkyt=AppConfig.jkyt;
        that.intback=AppConfig.jkintback;
        that.cash=AppConfig.jkcash;

        that.toast.error(res.mes,'',{timeOut:1000});

        

      }
    })
  }

  //提交数据
  onsumit(){
    let that=this;
    if(that.jkdw=='' || that.jkyt=='' || that.jkxm==''){
      that.toast.error("请将内容填写完整",'',{timeOut:1000});
      return false;
    }

    //将数据存入到对像中
    that.data.id=that.id;
    that.data.jk_price=that.jkprice;
    that.data.jkdw=that.jkdw;
    that.data.jkxm=that.jkxm;
    that.data.hkrq=that.hkrq;
    that.data.jkyt=that.jkyt
    that.data.intback=that.intback;
    that.data.cash=that.cash;
    if(Number(that.data.jk_price) != Number(that.data.intback)+Number(that.data.cash)){
      that.toast.error("现金跟网银之和不能大于总借款金额",'',{timeOut:1000});
      return false
    }
    that.borrow.getjkdmxAdds(that.data).subscribe(res=>{
      if(res.success==200){
        that.rou.navigate(['/loanapply',{jk_bh:that.jkbh}])
      }else{
        that.toast.error(res.mes,'',{timeOut:1000})
      }
    })
    console.log(that.data);
    return false
  }

  //下拉框
  select() {

    this.mask.showMask = true;
    this.mask.content = ['现金支付', '网银转账', '冲借款']
    if (this.mask.checked.length == 0) {
      for (var i = 0; i < this.mask.content.length; i++) {
        this.mask.isSelect.push(false)
      }
    }
  }



  //获取多选数据
  // getData() {
  //   if (this.mask.checked.length > 0) {
  //     for (var i = 0; i < this.mask.checked.length; i++) {
  //       this.myForm.patchValue(
  //         {
  //           ways: this.mask.checked + ' '
  //         }
  //       )
  //     }
  //   } else {
  //     this.myForm.patchValue(
  //       {
  //         ways: ''
  //       }
  //     )
  //   }


  // }

  //时间
  dateChange(e) {
    let date = new Date(e);
    console.log(date);
    let year = date.getFullYear();
    let month:any = date.getMonth() + 1;
      month = month < 10 ? '0' + month : month;
    let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    

    this.hkrq = year + '-' + month + '-' + d;
    console.log(this.hkrq);
  }

  ngOnInit(): void {
  }

}
