import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router'

import {BorrowService} from '../../server/borrow.service'
import { ToastrService } from 'ngx-toastr';

import * as localForage from "localforage";

@Component({
  selector: 'app-loantype',
  templateUrl: './loantype.component.html',
  styleUrls: ['./loantype.component.scss']
})
export class LoantypeComponent implements OnInit {

  public type:string="";  //点击进入的报销单类型
  public data:any={}; //接口报销参数

  public dataMx:any={}; //接口明细参数
  public tel:string="";
  public type_list:any;
  public jkbh:string=""; //生成的编号
  public type_id:string=""; //获取点击后的类型
  public type_status:any=0; //当前选中状态
  public type_statuss:any=0 
  public jkmc_count:any={};
  constructor(private route:Router, private rou:ActivatedRoute,private borrow:BorrowService,private toastr: ToastrService,
    ) { 
    let that=this;
    that.jkbh=that.rou.snapshot.params['jkbh']
    that.type=that.rou.snapshot.params['type']

    // this.rou.queryParams.subscribe(res=>{
    //     if(res.jkbh !=undefined){
    //       that.type=res.type;
    //       that.jkbh=res.jkbh;
    //     }
     
    // })

    console.log(that.jkbh);
    //调用当前登录的手机号
    //当报销单编号为空则创建bxd
    if(that.jkbh ==undefined){
      localForage.getItem("user").then(function(value:any){
        that.tel=value.tel;
        console.log(that.tel);
        //that.bxdAdd();
      })
    }else{
      //调用借款名称
      that.jktypemc();
    }

   
    

    this.bxdType();
  }

 //获取当前选中的借款名称
 jktypemc(){
  let that=this;
  that.borrow.getjktypemc({"jkd":that.jkbh}).subscribe(res=>{
    that.jkmc_count=res.data;
    console.log(res);
  })
}

  //调用类别数据
  bxdType(){
    let that=this;
    that.borrow.getBorrowType({"type":that.type}).subscribe(res=>{
      that.type_list=res.data;
     
    })
  }




  //获取点击的数据并加显示
  types(e,id){
    let that=this;
    let ids='';
   
    //判断当前是否选中，选择则取消
    if(that.type_list[e]['ACTIVE']=='active'){
      that.type_list[e]['ACTIVE']='';
    }else{


      if(that.jkbh !=''){

        //判断总数量大于1时
        if(that.jkmc_count.length>1){
            if(that.type_list[e]['NAME']=='评审费'){
              that.toastr.error("当前单号只可选择普通类型",'',{timeOut:1000});
              return false;
            }else{
             
              that.type_list[e]['ACTIVE']='active';
              console.log(that.type_list);
            }
           
        }
         
  
        //判断总数量为1时
        if(that.jkmc_count.length==1){
          if(that.jkmc_count[0]['JKMC'] =='评审费'){

            if(that.type_list[e]['NAME'] !='评审费'){

              that.toastr.error("当前单号只可选择评审费",'',{timeOut:1000});
              return false;
            }else{
              
              that.type_list[e]['ACTIVE']='active';

            }
           
          }else{
            if(that.type_list[e]['NAME'] !='评审费'){
              that.type_list[e]['ACTIVE']='active';

            }else{
              that.toastr.error("当前单号只可选择其他类型",'',{timeOut:1000});
              return false;
            }
          }
           
        }

        if(that.jkmc_count.length==0){
          if(that.type_list[e].NAME=='评审费'){
            var a=true
            for(var i=0;i<that.type_list.length;i++){
             if(that.type_list[i].NAME !='评审费' && that.type_list[i].ACTIVE=='active'){
               a=false
                 that.toastr.error("只可选择其他类型",'',{timeOut:1000});
                 break;
             }
            }
            if(a==true){
            
             that.type_list[e]['ACTIVE']='active';
   
            }
          
          }else{
           var b=true
           for(var i=0;i<that.type_list.length;i++){
             if(that.type_list[i].NAME =='评审费' && that.type_list[i].ACTIVE=='active'){
               b=false
                 that.toastr.error("只可选择评审费",'',{timeOut:1000});
                 break;
             }
            }
   
            if(b==true){
   
             that.type_list[e]['ACTIVE']='active';
   
            }
          } 
        }
  
  
  
      }

      
       if(that.type_list[e].NAME=='评审费'){
         var a=true
         for(var i=0;i<that.type_list.length;i++){
          if(that.type_list[i].NAME !='评审费' && that.type_list[i].ACTIVE=='active'){
            a=false
              that.toastr.error("只可选择其他类型",'',{timeOut:1000});
              break;
          }
         }
         if(a==true){
         
          that.type_list[e]['ACTIVE']='active';

         }
       
       }else{
        var b=true
        for(var i=0;i<that.type_list.length;i++){
          if(that.type_list[i].NAME =='评审费' && that.type_list[i].ACTIVE=='active'){
            b=false
              that.toastr.error("只可选择评审费",'',{timeOut:1000});
              break;
          }
         }

         if(b==true){

          that.type_list[e]['ACTIVE']='active';

         }
       }  
    }

    //获取选择的id号
    that.type_list.forEach(element => {
      if(element.ACTIVE=='active'){
        ids +=','+element.ID
      }
     
    });
   
    that.type_id=ids.substr(1);
  }

  //点击下一步触发添加至明细表中
  next(){
    let that=this;
    //判断是否已选择相应类型
    if(that.type_id==''){
      that.toastr.error("请选择类别",'',{timeOut:1000});
      return false;

    }else{

      let that=this;
      that.data.tel=that.tel;

     
      
      
      if(that.jkbh !=undefined){
        that.bxda();
       
      }else{

        if(that.data.tel==''){
          that.toastr.error("登录信息有误",'',{timeOut:1000});
          return false;
        }

        //调用生成编号规则接口
        that.borrow.getborrindex(that.data).subscribe(res=>{
          that.jkbh=res.data;
          that.bxda();

          console.log(that.jkbh);
        })
       }
   


    
    }
   
  }

  bxda(){
    let that=this;
    that.dataMx.jkd_bh=that.jkbh;
    that.dataMx.jkd_type=that.type_id;

    //查询当前报销单状态
    that.borrow.getjkdStu({"jkd_bh":that.dataMx.jkd_bh}).subscribe(res=>{
      if(res.success==200){
        if(res.data[0].STATUS =='审批中'){
          that.toastr.error("当前借款单正在审批中",'',{timeOut:1000});
        }else{
          //跳转添加类型修改中
          that.borrow.getjkmxAdd(that.dataMx).subscribe(res=>{
            //跳转至报销类别列表中
              that.route.navigate(['/loanapply',{'jk_bh':that.jkbh}])
          })

        }
      }else{
        that.toastr.error(res.mes,'',{timeOut:1000});
      }
    })


    
  }



  ngOnInit(): void {
  }

}
