import { Component, OnInit } from '@angular/core';

import {ApprService} from '../../server/appr.service'
import {ActivatedRoute,Router} from '@angular/router'
@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  public packup:any=[]    //是否收起  是true，否false
  public bxdbh:string="";
  public pid:string=''; //审批节点ID
  public data:any;  //数据集
  public name:string=''; //报销人
  public dep_name:string=''; //部门
  public create_date:string=''; //日期
  public reviewed_name:string='';  //审批人
  public bxr_qz:string=''; //报销人签字
  public status:string=''; //状态
  public actual_qz:string=''; //审批人签字
  constructor(
    private appr:ApprService,
    private rou:ActivatedRoute,
    private routr:Router
  ) {
      let that=this;
      that.bxdbh=that.rou.snapshot.params["bxdbh"];
      that.pid=that.rou.snapshot.params['pid'];

     

      that.apprinfo()
   }

  ngOnInit(): void {
  }

  //调用审批详情接口
  apprinfo(){
    let that=this;
    that.appr.getbxdmxInfo({"bxdbh":that.bxdbh,"pid":that.pid}).subscribe(res=>{
        if(res.success==200){
            that.name=res.data[0].USER_NAME;
            that.dep_name=res.data[0].DEP_NAME;
            that.create_date=res.data[0].CREATE_DATE;
            that.reviewed_name=res.data[0].REVIEWED_NAME;
            that.bxr_qz=res.data[0].BXR_QZ
            if(res.data[0].P_STATUS==1){
              that.status='已通过'
            }else{
              that.status='已退回'
            }
            //that.status=res.data[0].P_STATUS
            that.actual_qz=res.data[0].ACTUAL_QZ;
            that.data=res.data[0].mx  //列表数据
            that.data.forEach(element => {
                this.packup.push(true);
            });
            this.packup[0]= !this.packup[0];
        }
        console.log(res);
    })
  }


  //点击图片查看详情
  imginfo(id,mx_id){
    this.routr.navigate(["/accessory",{id:id,mx_id:mx_id,bxdbh:this.bxdbh}])
  }

  tuckup(index){
    this.packup[index]= !this.packup[index];
  }

}
