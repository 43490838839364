import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input()
  public modalContent:any
  @Output()
  private modalContentChange:EventEmitter<any>=new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  //关闭
  close() {
    this.modalContent.modalShow = false;
    this.modalContentChange.emit(this.modalContent);
    this.modalContent.canclefunc&&this.modalContent.canclefunc();
  }

  //确定
  confirm(){
    this.modalContent.modalShow = false;
    this.modalContentChange.emit(this.modalContent);
    this.modalContent.yesfunc&&this.modalContent.yesfunc();
  }
}
