//服务必引入injectable
import {Injectable} from '@angular/core';

//自定义配置文件
import {AppConfig} from "../confi/app-config";


//obj对象
import { Observable } from 'rxjs';

import { HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
@Injectable()

export class LoginService {
    httpOptions:any;
    
    constructor(private http: HttpClient) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    }

    //调用登录接口
    getLogin(data):Observable<any>{

        return this.http.post(AppConfig.baseUrl+"Login/login",this.getId(data));
    }

    //调用验证码
    getCode(data):Observable<any>{
        return this.http.post(AppConfig.baseUrl+"Login/codes",this.getId(data));

    }

    //调用修改密码接口
    getpwdZh(data):Observable<any>{
      return this.http.post(AppConfig.baseUrl+"Login/pwdZh",this.getId(data));

    }

    //调用注册接口
    getregister(data):Observable<any>{
      return this.http.post(AppConfig.baseUrl+"Login/register",this.getId(data));

    }

      //把所有参数转换成json字符
  private getId(e){
    const body = JSON.stringify([ 
     e
  ]);
  return body;

  }
}