import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router'
import * as localForage from "localforage";
import { ToastrService } from 'ngx-toastr';
import {MessageService} from '../../server/message.service'

@Component({
  selector: 'app-mlist',
  templateUrl: './mlist.component.html',
  styleUrls: ['./mlist.component.scss']
})
export class MlistComponent implements OnInit {

  @Input()
  public contentData:any=[]
  public index:number; //当前点击的索引
  public user_id:string=''; 
  public type:number=1 //删除还是查看
  public numb:number; //当前点击的消息ID
  public is_bxd:any;  //当前查看的状态 报销通知/审批通知
  public bxdbh:any;  //当前报销单
  public flowid:any='';

  public status:any; //报销通知当前审批状态
  constructor(
    private mess:MessageService,private toastr:ToastrService,private rou:ActivatedRoute,private rout:Router
  ) { 
    let that=this;
      localForage.getItem("user").then(function(value:any){
          that.user_id=value.id;
         
      })
  }

  ngOnInit(): void {
  }

  


  //删除
  delete(index,id){
   
    let that=this;
   
    that.type=2;
    that.numb=id;
    that.index=index;
    that.dealMess();
   
  }

  seeInfo(is_bxd,bxdbh,id,flowid,status){
    let that=this;
    that.is_bxd=is_bxd;
    that.bxdbh=bxdbh;
    that.type=1;
    that.numb=id,
    that.flowid=flowid;

    that.status=status;

    that.dealMess();
  }

  //删除、已读接口
  dealMess(){
  
    let that=this;
    that.mess.getMessageEdit({"numb":that.numb,"user":that.user_id,"type":that.type}).subscribe(res=>{
      //删除
        if(that.type==2){
          that.contentData.splice(that.index,1);
          that.toastr.success("删除成功",'',{timeOut:1000})
        }else{
          //查看
          if(that.is_bxd=='false'){
            //报销通知查看
            that.rout.navigate(["/detail",{"bxd_bh":that.bxdbh,"type":that.status}])

          }else{
            //审批通知
            that.rout.navigate(["/examine",{"bxdbh":that.bxdbh,"pid":that.flowid}])
          }
        }
    })
  }

}
