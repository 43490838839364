//服务必引入injectable
import {Injectable} from '@angular/core';

//自定义配置文件
import {AppConfig} from "../confi/app-config";


//obj对象
import { Observable } from 'rxjs';

import { HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
@Injectable()

export class JkdService {
    httpOptions:any;
    
    constructor(private http: HttpClient) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    }

    //调用获取当前用户下的评审单号接口
    getjkdpings(data):Observable<any>{

        return this.http.post(AppConfig.baseUrl+"Jkd/pingd",this.getId(data));
    }

    //获取当前评审单号的详情
    getjkdpinginfo(data):Observable<any>{

        return this.http.post(AppConfig.baseUrl+"Jkd/mxinfo",this.getId(data));
    }

    //调用评审费还款操作
    getjkdpingedit(data):Observable<any>{

        return this.http.post(AppConfig.baseUrl+"Jkd/pinghedit",this.getId(data));
    }
   

      //把所有参数转换成json字符
  private getId(e){
    const body = JSON.stringify([ 
     e
  ]);
  return body;

  }
}