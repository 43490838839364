import { Component, OnInit } from '@angular/core';
import * as localForage from "localforage";
 import {MineService} from '../../server/mine.service'
 import {ToastrService} from 'ngx-toastr'
 
 @Component({
  selector: 'app-loanlist',
  templateUrl: './loanlist.component.html',
  styleUrls: ['./loanlist.component.scss']
})
export class LoanlistComponent implements OnInit {
  public listData:any=[];
  public index:number=0;
  public id:any='';
  public type_index='未提交'
  constructor(private mine:MineService) { 
    let that=this;
    localForage.getItem("user").then(function(value:any){
     
      if(value !=null){
        that.id=value.id;

       
      }
      that.mineInfo();
    })


  }

  ngOnInit(): void {
  }


  //调用个人借款数据
  mineInfo(){
    let that=this;
    that.mine.getMineJk({'id':that.id,'type':that.type_index}).subscribe(res=>{
      console.log(res);
      if(res.success==200){
        that.listData=res.data;
      }
    })
  }
  type(index){
    this.index = index;
    if(index==0){
      this.type_index='未提交'
    }else if(index==1){
      this.type_index='审批中'
    }else{
      this.type_index='已通过'
    }
    this.mineInfo();
  }
}
