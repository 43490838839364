export class AppConfig {
    static baseUrl: string = 'http://api.bx.gmread.cn/api.php/';//1.5
    
    static appid:string="wxa36ab8eff85a35b6"; //微信公众号APPID

    //static redirect_uri :string= 'http://test.gmlogin.aiyimu.cn/wxlogins/info.php';
    static redirect_uri :string= 'http://api.bx.gmread.cn/api.php/index/deal_redirect';
    static agent_name:string="" //代理商管理列表中搜索内容
    static agent_types:string="选择" //代理商管理列表中下拉内容

    static user:any={} //缓存数据

    static sqxm:string='' //申请项目
    static remarks:string='' //备注

    static cash_payment:any=''; //现金支付
    static internet_bank:any=''; //网银转账
    static searchValue:any='';  //存储历史记录
    static invitecodeMask:any={
        is_show:false,
        name:"机构名称",
        func:'',
        qrcode:'',
      };  //邀请码

      static indexs:number=0  //定位我的报销页面切换的位置
      static indexstype:string='未报销'  //定位我的报销页面切换的位置文字

      static fpr_qz:any='' //签字人


      static jkdw:string='';  //评审单位
      static jkyt:string='';  //借款用途
      static jkintback:string='';  //网银支付
      static jkcash:string='';  //现金支付

      static fuktype:number=0;  //付款列表默认一级索引
      static fukkey:number=0; //付款列表默认二级索引

}