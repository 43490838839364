import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {HomesService} from '../../server/homes.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-plist',
  templateUrl: './plist.component.html',
  styleUrls: ['./plist.component.scss']
})
export class PlistComponent implements OnInit {

  @Input()
  public contentData:any=[];

  //弹出提示框内容
  public modal: any = {
    modalShow: false,
    text: '确认是否已付款',
    btnText: [],
    yesfunc: '',
    canclefunc: '',
  }
  constructor(private route:Router,private home:HomesService,private toast:ToastrService) { }

  ngOnInit(): void {
  }




  godetail(e){
    if(this.contentData.types==0){
      this.route.navigate(['/paymember/pdetail',{bxdbh:e}]);

    }else{
      this.route.navigate(['/paymember/jkdetail',{bxdbh:e}]);
      
    }
  }

  //确认付款动作
  queren(e,index){
    let that=this;
    that.modal.modalShow=true;
    that.modal.btnText=['取消','确认']
    that.modal.yesfunc=function(){
      that.home.getPyaEdit({"bxdbh":e}).subscribe(res=>{
          that.contentData.splice(index,1);
          that.toast.success("操作成功",'',{timeOut:1000})
      })
    }
   
  }

}
