import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BxdService } from '../../server/bxd.service'
import { ToastrService } from 'ngx-toastr';
import { SignaturePad } from 'angular2-signaturepad'
import { AppConfig } from 'src/app/confi/app-config';
import * as localForage from "localforage";
@Component({
  selector: 'app-noticket',
  templateUrl: './noticket.component.html',
  styleUrls: ['./noticket.component.scss']
})

export class NoticketComponent implements OnInit {

  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;

  public bxd_bh: string = '';
  public real: string = '';
  public data: any = {}; //接口参数
  public jkdata: any = {}; //借款接口参数
  public bxd_info: any;  //头部接口数据
  public bxd_type_info: any;  //列表数据
  public price: number = 0; //总报销金额
  public final_price: number = 0; //最终报销金额
  public packup: any = [false]    //是否收起  是true，否false]
  public relation: any = '0'; //是否关联借款单
  public repay: any = '0'; //是否还款
  public must: number = 0; //是否存在必须的还借款
  public jk_total: number = 0; //总借款金额
  public hk_total: number = 0; //总还款金额
  public cash_payment: number = 0; // 还款现金支付
  public internet_bank: number = 0; // 还款网银支付
  public if_getjkd: number = 0; // 设置借款单调用0可以1不能
  public jklist = [{ "name": '是', "value": 1 }, { "name": '否', "value": 0 }];
  public payees = []; //实际收款人数组
  public payees_id: any = 0;  //实际收款人id
  public is_payes: any = '1'; //是否为实际收款人
  public is_use_payee = false  //是否显示

  public is_sk_zhs: any = '0';//是否修改收款帐号


  public is_sk_b = false  //是否显示修改收款帐号输入框

  public zhanghao: string = '';  //修改后的收款帐号值


  public bank_card: string = ''; //实际账户

  //支付方式多选框
  public mask: any = {
    showMask: false,
    content: [],
    checked: [],
    isSelect: []
  };

  //借款单号
  public numMask: any = {
    showMask: false,
    content: [],
    checked: [],
    isSelect: [],
    price: []
  };

  public ways: any = '';  //支付方式
  public numbers: any = '';  //借款单号

  signatureImage: any = '';
  public signaturePadOptions: Object = {
    minWidth: 0.5,// 比划的粗细,
    canvasWidth: 300, // 可写宽度
    canvasHeight: 92,// 可写高度
  };

  //弹出提示框内容
  public modal: any = {
    modalShow: false,
    text: '',
    btnText: [],
    yesfunc: '',
    canclefunc: '',
  }
  constructor(private toast: ToastrService, private rou: Router, private rount: ActivatedRoute, private bxds: BxdService) {

    let that = this;

    this.signaturePadOptions = {
      minWidth: 0.5,// 比划的粗细,
      canvasWidth: ((window.innerWidth * 0.92) - 45), // 可写宽度
      canvasHeight: ((window.innerWidth * 0.92) * 0.273),// 可写高度
    }



    that.bxd_info = [];
    that.bxd_type_info = [];
    //接收参数报销单
    that.bxd_bh = that.rount.snapshot.params['bxd_bh'];
    //调用接口方法
    that.bxdTypeList()
    setTimeout(function () {
      that.jk_repay('1');  //获取总金额
    }, 100)
    localForage.getItem("user").then(function (value: any) {
      that.jkdata.uid = value.id;
    })
    that.payee();

  }

  is_sk_zh(e) {
    let that = this;
    that.is_sk_b = !that.is_sk_b;
    that.is_sk_zhs = e;
  }


  is_paye(e) {
    let that = this;
    that.is_use_payee = !that.is_use_payee;
    that.is_payes = e;
    if (e == '1') {
      that.is_sk_b = false;
      that.is_sk_zhs = '0';
      that.payees_id = 0
    }
  }


  //实际收款人信息
  payee() {
    let that = this;
    that.bxds.getPayee().subscribe(res => {
      if (res.success == 200) {
        that.payees = res.data;
      }
    })
  }


  //下拉框
  select(index) {
    if (index == 1) {
      this.mask.showMask = true;
      this.mask.content = ['现金支付', '网银转账']//,'冲借款'
      if (this.mask.checked.length == 0) {
        for (var i = 0; i < this.mask.content.length; i++) {
          this.mask.isSelect.push(false)
        }
      }
    } else {
      // 这里填写获取借款单内容
      let that = this;
      // 报销单编号
      that.jkdata.bxd_bh = that.bxd_bh;
      if (that.if_getjkd == 0) {
        that.bxds.getjkd(that.jkdata).subscribe(res => {
          if (res.success == 200) {

            if (res.data.length > 0) {
              that.if_getjkd = 1;
              // 有数据的情况下的操作
              that.numMask.showMask = true;
              // 内容
              that.numMask.content = [];
              // 价格
              that.numMask.price = [];
              that.numMask.isSelect = [];
              that.numMask.checked = [];
              for (var i = 0; i < res.data.length; i++) {
                // 拼接内容
                that.numMask.content.push(res.data[i]['JKD_BH']);
                // 拼接价格
                that.numMask.price[res.data[i]['JKD_BH']] = res.data[i]['TOTAL'];
                if (res.data[i]['CHECKED'] == '1') {
                  that.numMask.isSelect.push(true);
                  that.numMask.checked.push(res.data[i]['JKD_BH']);
                } else {
                  that.numMask.isSelect.push(false);
                }
              }
            } else {
              that.relation = 0;
              that.toast.error('暂无数据，无法选择', '', { timeOut: 1000 });
            }
          } else {
            that.toast.error(res.mes, '', { timeOut: 1000 });
            // that.rou.navigate(['/home'])
          }
        })
      } else {
        // 有数据的情况下的操作
        that.numMask.showMask = true;
      }
    }

  }

  //选择实际收款
  skxxchoose() {
    this.rou.navigate(['/gatheringInfo'])
  }

  // get_jkd(){
  //   let that = this;
  //   that.bxds.getjkd(that.data).subscribe(res => {
  //     if (res.success == 200) {
  //       that.bxd_info = res.data.bxd_info[0];
  //       for (var i = 0; i < res.data.mx.length; i++) {
  //         res.data.mx.showDel = false
  //       }
  //       that.bxd_type_info = res.data.mx;
  //       if (res.data.price == null) {
  //         that.price = 0;

  //       } else {
  //         that.price = res.data.price;

  //       }
  //     } else {
  //       that.toast.error(res.mes, '', { timeOut: 1000 });
  //       // that.rou.navigate(['/home'])
  //     }

  //   })
  // }
  //获取多选数据
  getData() {
    if (this.mask.checked.length > 0) {
      this.ways = this.mask.checked + ''
    } else {
      this.ways = ''
    }
  }
  // 选择借款单内容并操作
  getNum() {
    console.log(this.numMask.checked)
    if (this.numMask.checked.length > 0) {
      this.numbers = this.numMask.checked + '';
      let total = 0;
      for (let index = 0; index < this.numMask.checked.length; index++) {
        total += Number(this.numMask.price[this.numMask.checked[index]] * 1000);
      }
      // 借款总金额判断
      this.jk_total = total / 1000;
      // 调用还款的接口
      this.jk_repay('1');
      // console.log(this.numMask.price[this.numMask.checked]);
      // this.jk_total += parseInt();
    } else {
      this.jk_total = 0;
      this.numbers = ''
    }
  }
  set_platform(e) {

    if (this.is_payes == '1') {
      this.bank_card = '';
    } else {
      for (let i = 0; i < this.payees.length; i++) {
        if (this.payees[i].ID == e) {
          this.bank_card = this.payees[i].BANK_CARD;
          break;
        }
      }
    }


  };

  drawComplete() {
    let that = this;
    this.signatureImage = this.signaturePad.toDataURL();
    AppConfig.fpr_qz = this.signatureImage;

  }
  //调用报销单列表
  bxdTypeList() {
    let that = this;
    that.data.bxd_bh = that.bxd_bh;
    that.bxds.getbxdmxType(that.data).subscribe(res => {
      if (res.success == 200) {
        that.bxd_info = res.data.bxd_info[0];
        for (var i = 0; i < res.data.mx.length; i++) {
          res.data.mx.showDel = false
        }
        that.relation = that.bxd_info.JK_LINK;
        that.hk_total = that.bxd_info.HK_TOTAL;
        that.repay = that.bxd_info.PAY_BACK;
        that.numbers = that.bxd_info.BXD_JK;
        that.jk_total = that.bxd_info.HJK_TOTAL;
        that.repay = that.bxd_info.PAY_BACK;
        that.cash_payment = that.bxd_info.BACK_CASH;
        that.internet_bank = that.bxd_info.BACK_BANK;
        that.bxd_type_info = res.data.mx;
        that.must = Number(res.data.must);
        if (res.data.price == null) {
          that.price = 0;
        } else {
          that.price = res.data.price;
        }
        // 如果未关联，之心一次，显示最新的金额
        if (that.relation == '0') {
          that.jk_repay('0');
        } else {
          that.jk_repay('1');
        }
      } else {
        that.toast.error(res.mes, '', { timeOut: 1000 });
        that.rou.navigate(['/home'])
      }

    })
  }


  //删除
  delete(index, id) {
    //调用删除接口
    let that = this;
    that.modal.modalShow = true;
    that.modal.text = "是否删除";
    that.modal.btnText = ['取消', '确定']
    //确认按钮
    that.modal.yesfunc = function () {
      that.bxds.getbxdmxDel({ "id": id }).subscribe(res => {
        if (res.success == 200) {
          that.toast.success(res.mes, '', { timeOut: 1000 })

          that.bxd_type_info.splice(index, 1);

        } else {
          that.toast.error(res.mes, '', { timeOut: 1000 })

        }
      })
    }

    // //取消按钮
    // that.modal.canclefunc=function(){
    //   that.toast.success("取消删除",'',{timeOut:1000})
    // }

  }

  //添加报销单类型
  add() {


    let that = this;
    that.bxdType();
  }
  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.signaturePad.clear();

    if (AppConfig.fpr_qz != '') {
      this.signaturePad.fromDataURL(AppConfig.fpr_qz);
    }


  }


  //清除画布
  drawClear() {
    this.signaturePad.clear();
    AppConfig.fpr_qz = '';
  }

  //查询当前报销单状态是否可添加类型
  bxdType() {

    let that = this;

    that.bxds.getBXDz({ "bxdbh": that.bxd_bh }).subscribe(res => {
      if (res.success == 200) {
        if (res.data[0].STATUS == '审批中') {
          that.toast.error("当前报销单正在审批中", '', { timeOut: 1000 });
        } else {
          //跳转添加类型
          that.rou.navigate(['/choosetype'], {
            queryParams: {
              'bxdbh': that.bxd_bh,
              'type': that.bxd_info.BXD_TYPE
            }
          })

        }
      } else {
        that.toast.error(res.mes, '', { timeOut: 1000 });
      }
    })
  }

  //调用提交报销单审核流程
  sumit() {
    let that = this;



    for (var i = 0; i < that.bxd_type_info.length; i++) {
      if (this.relation == '0' && that.bxd_type_info[i].BX_PRICE == 0) {
        that.toast.error(that.bxd_type_info[i].NAME + "金额不能为0", '', { timeOut: 1000 });
        return false

      }
    }
    //判断是否选择实际付款人
    if (that.payees_id == 0 && that.is_payes == '0') {
      that.toast.error("请选择实际付款人", '', { timeOut: 1000 });
      return false;
    }

    //判断是否选择了修改账户信息
    if (that.is_sk_zhs == '1' && that.zhanghao == '') {
      that.toast.error("请输入要修改的收款帐号信息", '', { timeOut: 1000 });
      return false;
    }

    //判断是否选则借款单
    if (this.relation == 0) {
      console.log(this.must);
      if (this.must > 0) {
        that.toast.error("您有借款单即将到期，请关联借款单", '', { timeOut: 1000 });
      } else {
        //判断总金额是否为0
        console.log(that);
        if (Number(that.final_price) == 0) {
          that.toast.error("还款金额填写不匹配", '', { timeOut: 1000 });
        } else {

          //是否有签名
          if (AppConfig.fpr_qz != '') {
            that.jekou();  //调用接口
          } else {
            that.toast.error("请补充签名", '', { timeOut: 1000 });
          }
        }
      }
    } else {

      if (this.numMask.checked.length == 0) {
        that.toast.error("请选择需要还款的单子", '', { timeOut: 1000 });
        return false;
      }

      // 判断是否输入金额
      if (that.hk_total == 0) {
        that.toast.error("请输入要还款的金额", '', { timeOut: 1000 });
        return false;
      }


      // 判断是否有还款
      if (that.repay == '1') {
        //金额得0正常走
        if ((Number(that.final_price) + Number(that.cash_payment) + Number(that.internet_bank) == 0)) {
          //是否有签名
          if (AppConfig.fpr_qz != '') {
            that.jekou();  //调用接口
          } else {
            that.toast.error("请补充签名1", '', { timeOut: 1000 });
          }
        } else {
          that.toast.error("金额填写不匹配", '', { timeOut: 1000 });

        }
      } else {
        //金额得0正常走
        if ((Number(that.final_price) >= 0)) {
          //是否有签名
          if (AppConfig.fpr_qz != '') {
            that.jekou();  //调用接口
          } else {
            that.toast.error("请补充签名", '', { timeOut: 1000 });
          }
        } else {
          that.toast.error("金额填写不匹配", '', { timeOut: 1000 });

        }
      }
    }
  }


  //调用接口
  jekou() {
    let that = this;
    //调用签名入库
    that.bxds.getqm({ "bxd_bh": that.bxd_bh, "qz": AppConfig.fpr_qz, 'type': 0 }).subscribe(res => {
      //调用流程接口 
      // JK_LINK      是否关联借款单0未关联1关联    relation
      // PAY_BACK     是否还款：0否1是              repay
      // BACK_CASH    现金还款                      cash_payment
      // BACK_BANK    网银还款                      internet_bank
      // numbers      单号                          numbers
      // that.bxds.getflow({ "real": that.real, "bxd_bh": that.bxd_bh, "relation": this.relation, "repay": this.repay, "case_payment": this.cash_payment, "internet_bank": this.internet_bank, "number": this.numbers, "hk_total": this.hk_total}).subscribe(res => {
      that.bxds.gettestflow({ "zhanghao": that.zhanghao, 
      "payee_id": that.payees_id, 
      "real": that.real, 
      "bxd_bh": that.bxd_bh, 
      "relation": this.relation, 
      "repay": this.repay, 
      "cash_payment": this.cash_payment, 
      "internet_bank": this.internet_bank, 
      "numbers": this.numbers, 
      "hk_total": this.hk_total }).subscribe(res => {
        if (res.success == 200) {
          that.toast.success(res.mes, '', { timeOut: 1000 });
          AppConfig.fpr_qz = ''
          setTimeout(function () {
            that.rou.navigate(['/mylist']);
          }, 1100)

        } else {
          that.toast.error(res.mes, '', { timeOut: 1000 });

        }
      })
    })

  }


  // 对比获取金额
  jk_repay(numb) {
    console.log(numb);
    let that = this;
    let price = Number(that.price);
    if (numb == '0') {
      that.final_price = price;
    } else {
      // 获取输入的金额
      let jk = Number(that.jk_total);
      let hk = Number(that.hk_total);
      // 还款多于借款，提示填写错误
      if (jk < hk) {
        that.hk_total = 0;// 还款总金额清零
        that.cash_payment = 0;// 现金还款清零
        that.internet_bank = 0;// 银行还款清零
        // console.log('赋值后第一个值：'+this.jk_total);
        // console.log('赋值后第二个值：'+this.hk_total);
        that.toast.error("还款金额不得超出总借款金额，请重新填写", '', { timeOut: 1000 });
      } else {
        console.log(price, hk);
        // 判断还款金额和总报销金额对比
        if (price >= hk) {
          console.log('1>2');
          // 报销多于还款，不能操作还款
          that.repay = '0';
        } else {
          console.log('1<2');
          // 报销小于还款，必须还款
          that.repay = '1';
        }
        that.final_price = (Number(price * 1000) - Number(hk * 1000)) / 1000;
      }
    }
  }

  tiaoz(id, bxdbh, typebxd, name, bh) {
    if (name == '评审费') {
      this.rou.navigate(['/reviewfee'], {
        queryParams: {
          id: id,
          bxd_bh: bxdbh,
          typename: name,
          bh: bh
        }
      });
      return false;
    }

    if (typebxd == '普通') {
      this.rou.navigate(['/generalfillout'], {
        queryParams: {
          id: id,
          bxd_bh: bxdbh
        }
      })
    } else if (typebxd == '无票') {
      this.rou.navigate(['/fillout'], {
        queryParams: {
          id: id,
          bxd_bh: bxdbh,
          typename: name
        }
      })
    }
  }

  tuckup(index) {
    this.packup[index] = !this.packup[index];
  }



}
