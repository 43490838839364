import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {BxdService} from '../../server/bxd.service'
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
 public bxd_bh:string='';  
  public packup:any=[true,true];    //是否收起  是true，否false

  public packups:any=[true,true]   //是否收起  是true，否false

  public bxd_type:string='';  //报销单状态 
  public data:any={};  //当前头部数据信息
  public back_total:number = 0;
  public sp_list:any={};
  public money:any={};
  constructor(private toast:ToastrService, 
              private rou:Router, 
              private rount:ActivatedRoute,
              private bxds:BxdService
              ) { 
    let that=this;
     //接收参数报销单
     that.bxd_bh=that.rount.snapshot.params['bxd_bh'];
     that.bxd_type=that.rount.snapshot.params['type'];
    
     that.bxdinfo();
  }


  //根据报销单查询出详情数据
  bxdinfo(){
    let that=this;
    that.bxds.getbxinfo({"bxdbh":that.bxd_bh,"type":that.bxd_type}).subscribe(res=>{
      if(res.success==200){
        that.data=res.data[0]
        that.sp_list=res.data[0].sp_list[0]
        that.money = res.data[0].money[0];
        console.log(that.money);
        that.data.bxdmx.forEach(element => {
          that.packup.push(true);
        });
        //默认第一个展开
        that.packup[0]= !this.packup[0];

        that.data.pings.forEach(element => {
          that.packups.push(true);

        });
        //默认第一个展开
        that.packups[0]= !this.packups[0];
        
      }else{
        that.toast.error(res.mes,'',{timeOut:1000});
        that.rou.navigate(['/home']);
      }
     
    })
  }

  ngOnInit(): void {
  }

  //点击图片查看详情
  imginfo(id,status,mx_id,type_name){
    console.log(type_name);
    if(type_name !='收据'){
      this.rou.navigate(["/accessory",{id:id,bxdbh:this.bxd_bh,status:status,mx_id:mx_id}])

    }else{
      this.rou.navigate(["/faillacc",{id:id,bxdbh:this.bxd_bh,status:status,mx_id:mx_id}])

    }
  }

  tuckups(index){
    this.packups[index]= !this.packups[index];

  }

  tuckup(index){
    this.packup[index]= !this.packup[index];
  }

  //点击退回按钮操作
  tuihui(){
    let that=this;
    that.rou.navigate(["/noticket",{bxd_bh:that.bxd_bh}])
  }

}
