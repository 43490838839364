import { Component, OnInit, HostListener } from '@angular/core';
import {Router,ActivatedRoute, NavigationEnd,} from '@angular/router'
import {BxdService} from '../../server/bxd.service'
import { ToastrService } from 'ngx-toastr';

import { AppConfig } from '../../confi/app-config';
import { PlatformLocation } from '@angular/common';
import { async } from '@angular/core/testing';



@Component({
  selector: 'app-fillout',
  templateUrl: './fillout.component.html',
  styleUrls: ['./fillout.component.scss']
})
export class FilloutComponent implements OnInit {

  //多选框
 public mask: any = {
  showMask: false,
  content: [],
  checked: [],
  isSelect: []
};
public files: any = [];
//弹出提示框内容
public modal: any = {
  modalShow: false,
  text: '是否要上传附件',
  btnText: [],
  yesfunc: '',
  canclefunc: '',
}


//加载中内容
public load: any = {
  modalShow: false,
  text: '加载中',
 
}

  public id:any;  //报销明细ID
  public bxdbh:string='';  //报销单编号
  public create:string=''; //时间
  public dep_name:string=''; //部门
  public name:string=''; //姓名
  public type_name:string=''; //报销类型

  public sqxm:string=""; //申请项目
  public remarks:string=""; //备注
  public bx_price:any; //报销总金额
  public cash_payment:any; //现金支付
  public internet_bank:any; //网银转账
  public img:any=[];  //图片数据
  public status:string="";  //状态
  public datas:any={};  //定义参数
  public img_id:any=0 //上传后的地址
  public ty:string; //上传类型
  public img_count:number=0 //图片总数
  public img_counts:number=0 //图片总数只差
  public bxd_bh:string='' //当前跳转过来的报销单
  public typename:string='' //当前选中的类型

  public a:string='';
  public b:string='';
  constructor(private rou:Router,private rout:ActivatedRoute,private bxdi:BxdService,private toast:ToastrService,private $location:PlatformLocation) { 

    let that=this;
    that.rout.queryParams.subscribe(res=>{
      if(res){
          that.id=res.id;
          that.bxd_bh=res.bxd_bh,
          that.typename=res.typename
          that.headinfo();
          that.getBXmx();
          that.bxdStus();
      }
    })

  }


//获取报销单明细信息头部数据
   headinfo(){
     let that=this;
    that.bxdi.getbxdmxHeadinfo({"id":that.id}).subscribe(res=>{
      if(res.success==200){
        that.bxdbh=res.data[0].BXD_BH;
        that.type_name=res.data[0].TYPE_NAME;
        that.name=res.data[0].bxdinfo.USER_NAME;
       
        that.dep_name=res.data[0].bxdinfo.DEP_NAME;
        that.create=res.data[0].bxdinfo.CREATE_DATE;
      }else{
        that.toast.error(res.mes,'',{timeOut:1000});
        that.rou.navigate(['/home'])
      }
       
    })
   }

   sqxms(e){
    AppConfig.sqxm=e;
  }
 
  remark(e){
    AppConfig.remarks=e;
  }
  cash_payments(e){
   
     //if(vali(e)){
      AppConfig.cash_payment=e
     // }else{
     //   console.log("aaa")
     //   AppConfig.cash_payment='';
     //   this.cash_payment=null
     // };
   
  }

  internet_banks(e){

   //if(vali(e)){
     AppConfig.internet_bank=e;
   // }else{
    
   //   AppConfig.internet_bank='';
   //   this.internet_bank=''
   // };

   
  }

  fileChanges(file){
    //上传发票
    let that=this;
    that.ty='发票';
    if(that.status !='审批中'){

      if (file.length === 0) {
        return;
      }
    const reader = new FileReader();
    let formData:FormData = new FormData();
    reader.readAsDataURL(file[0]) // input.files[0]为第一个文件
    reader.onload = ()=>{
      var aaa:any=reader.result;
      formData.append('file', aaa);
      formData.append("typesss",'无票');

      formData.append('type', '发票');

      formData.append('bxd_bh', that.bxdbh);
    
      formData.append('bxd_mx_id', that.id);

      formData.append('img_id','0' );
      that.filess(formData);
    }
    
    }else{
      that.toast.error("正在审核中不可修改",'',{timeOut:1000})
    }
  }


  fileChange(file){
    let that=this;
    if(this.typename=='收据'){
      this.a='无票';
      this.b='收据'
    }else{
      this.a='无票';
      this.b=''
     
    }
   
    that.ty='附件'
    
    console.log(file);
    if (file.length === 0) {
      return;
    }
    const reader = new FileReader();
    let formData:FormData = new FormData();
    for(var i=0;i<file.length;i++){
      formData.append('file[]', file[i]);
    }

      setTimeout(function(){
        formData.append('type', '附件');

        formData.append('bxd_bh', that.bxdbh);
      
        formData.append('bxd_mx_id', that.id);
  
        if(this.typename=='收据'){
          formData.append('img_id', '0');
        }else{
          formData.append('img_id', that.img_id);
        }
        that.filess(formData);
      },1000)
     


  // for(var i=0;i<file.length;i++){
  //   const reader = new FileReader();

  //   reader.readAsDataURL(file[i]) // input.files[0]为第一个文件
  //   reader.onload = ()=>{

  //     let formData:FormData = new FormData();
  //     let aa:any=reader.result;
     
  //     formData.append('file', aa);

  //     formData.append('type', '附件');

  //     formData.append('bxd_bh', that.bxdbh);
    
  //     formData.append('bxd_mx_id', that.id);

  //     if(this.typename=='收据'){
  //       formData.append('img_id', '0');
  //     }else{
  //       formData.append('img_id', that.img_id);
  //     }

      
  //      //上传附件
  //      return that.filess(formData);
  //   }

  //   }
    
  }

  //上传接口
  filess(form){
    
    let that=this;
    
    if(that.b=='发票'){
      that.load.modalShow=true;
      that.load.text='正在上传发票识别中...'
    }else{
      that.load.modalShow=true;
      that.load.text='上传附件中...'
    }
    if(this.b=='收据'){

      that.bxdi.getWbxdfile(form).subscribe(res=>{
          //重新加载

            that.imgInfo();
            that.load.modalShow=false;
           
         
      })

    }else{
      that.bxdi.getfile(form).subscribe(res=>{
        that.img_id=res.data.id
        if(res.success==200){
          if(this.b=='发票'){
            that.load.modalShow=false;
  
            that.modal.text= '发票上传成功是否继续上传附件',
    
            that.modal.modalShow = true;
            that.modal.btnText = ['取消','确定']
      
            that.modal.yesfunc = function(){
              //触发上传附件功能
              var oInput = document.getElementById("enc");
              oInput.click();
            }
          }else{
            that.load.modalShow=false;
            that.toast.success(res.mes,'',{timeOut:1500});

          }
        }else{
          that.load.modalShow=false;
          that.toast.error(res.mes,'',{timeOut:1500});
         
        }
         //重新加载
         that.imgInfo();
       
      })
    }
    
  }

  //查询当前报销单状态
  bxdStus(){
    let that=this;
    that.bxdi.getbxdS({"bxdbh":that.bxd_bh}).subscribe(res=>{
      that.status=res.data
    })
  }

  imgInfos(e){
    let that=this;
    if(that.typename=='发票')
    that.rou.navigate(["/accessory",{"id":e,"bxdbh":that.bxdbh,"mx_id":that.id,'status':that.status}]);
    else
    that.rou.navigate(["/faillacc",{"id":e,"bxdbh":that.bxdbh,"mx_id":that.id,'status':that.status}]);

  }

  delete(e){
    let that=this;

    if(that.status !='审批中'){
      that.modal.modalShow = true;
      that.modal.text='是否要删除'
      that.modal.btnText = ['取消','确定']
  
      that.modal.yesfunc = function(){
        that.bxdi.getDelimg({"id":e,"type":"发票","mx_id":that.id}).subscribe(res=>{
          if(res.success==200){
            that.toast.success(res.mes,'',{timeOut:1000});
            that.imgInfo();
          }
        })
      }
      // that.modal.canclefunc=function(){
      //   that.toast.success('取消删除','',{timeOut:1000});
      // }
    }else{
      that.toast.error("正在审核中不可修改",'',{timeOut:1000})
    }
  }

   //调用图片显示功能
   imgInfo(){
  
    let that=this;

    that.bxdi.getimgInfo({"id":that.id}).subscribe(res=>{
      
      that.img=res.data.img_data
      that.bx_price=res.data.price,
      that.img_count=res.data.img_count,
      that.img_counts=res.data.img_counts
    })
  }



  upload(){
    if(this.typename=='收据'){
    //触发上传附件功能
    this.b='收据'
        var oInput = document.getElementById("enc");
        oInput.click();
    }else{
      this.b='发票'
      console.log("aaa");
      var oInput = document.getElementById("fa");
      oInput.click();
    }
    
  }


    //获取当前报销明细数据是否有值
    getBXmx(){
      let that=this;
      that.bxdi.getWbxdmx({"id":that.id}).subscribe(res=>{
        if(res.success==200){
  
          //报销总金额
          if(res.data[0].BX_PRICE==0){
            that.bx_price='';
          }else{
            that.bx_price=res.data[0].BX_PRICE;
          }
          
          //网银支付
          if(res.data[0].INTERNET_BANK==0){
            that.internet_bank='';
          }else{
            that.internet_bank=res.data[0].INTERNET_BANK;
          }
  
          //现金支付
          if(res.data[0].CASH_PAYMENT==0){
            that.cash_payment='';
  
          }else{
            that.cash_payment=res.data[0].CASH_PAYMENT;
  
          }
          that.sqxm=res.data[0].SQXM;
          that.remarks=res.data[0].REMARKS
          that.img=res.data[0].img_data
          that.img_count=res.data[0].img_count
          that.img_counts=res.data[0].img_counts
        }else{
         
          that.remarks=AppConfig.remarks;
          that.internet_bank=AppConfig.internet_bank;
          that.cash_payment=AppConfig.cash_payment;
  
          that.toast.error(res.mes,'',{timeOut:1000});
  
          
  
        }
      })
    }



   editmx(){
    let that=this;

    if(that.status !='审批中'){


     

      that.datas.id=that.id;
     // that.datas.sqxm=that.sqxm;
      that.datas.cash_payment=Number(that.cash_payment);
      that.datas.internet_bank=Number(that.internet_bank);
      that.datas.remarks=that.remarks;
      that.datas.sqxm=that.sqxm;
      that.datas.bx_price=Number(that.bx_price);
      console.log(that.datas.remarks);

      if(that.datas.bx_price==''){
        that.toast.error("总金额不能为空",'',{timeOut:1000});
        return false;
      }
      //判断是否有发票
      if(that.img_count==0){
        that.toast.error("发票/附件不能为空",'',{timeOut:1000});
        return false;
      }
      if(that.datas.remarks=='' || that.datas.remarks==null){
        that.toast.error("备注不可为空",'',{timeOut:1000});
        return false;
      }

      //判断总金额是否跟网银转账+现金是一至的
      console.log(that.datas.cash_payment+that.datas.internet_bank);
      if(that.datas.bx_price !=(that.datas.cash_payment+that.datas.internet_bank)){
        that.toast.error("总金额跟网银及现金之和不一致",'',{timeOut:1000});
      }else{
        //调用更新接口
        that.bxdi.getWpEdit(that.datas).subscribe(res=>{
          if(res.success==200){
            //提交数据后将输入的缓存记录清空
            AppConfig.remarks='';
            AppConfig.internet_bank='';
            AppConfig.cash_payment='';
            

            // var absurl =window.location.protocol + '//' + window.location.host + that.rou.url;
            // document.removeEventListener("popstate", absurl,false);
            // document.removeEventListener('touchstart',absurl, false)
            // setTimeout(function(){
              that.rou.navigate(['/noticket',{bxd_bh:that.bxdbh}])

            //},1000)
          
            
          }else{
            that.toast.error(res.mes,'',{timeOut:1000});
          }
        })
      }
     
    }else{
      that.toast.error("正在审核中不可修改",'',{timeOut:1000})

    }
    return false;
  }


  ngOnInit(): void {
  }

}
