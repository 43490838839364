import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { BxdService } from '../../server/bxd.service'
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-pdf-affix',
  templateUrl: './pdf-affix.component.html',
  styleUrls: ['./pdf-affix.component.scss']
})
export class PdfAffixComponent implements OnInit {
  //加载中内容
  public load: any = {
    modalShow: false,
    text: '加载中',

  }

  //弹出提示框内容
  public modal: any = {
    modalShow: false,
    text: '是否要上传附件',
    btnText: [],
    yesfunc: '',
    canclefunc: '',
  }

  public id: string = '';
  public bxdbh: string = '';  //报销单编号
  public fileList: any;
  public name:string = '';  //发票名称
  public count:number; // 附件数
  public status: string = "";  //状态
  constructor(private rou: Router, private router: ActivatedRoute, private toast: ToastrService, private bxdi: BxdService) {
    this.id = this.router.snapshot.params['id']
    this.bxdbh = this.router.snapshot.params['bxdbh']
  }

  ngOnInit(): void {
    this.bxdStus()
    this.getData()
  }

  getData() {
    this.bxdi.getPdfInfo({
      id: this.id
    }).subscribe(res => {
      if (res.success == 200) {
        this.fileList = res.data[0].FJ
        this.name = res.data[0].FILE_NAME
        this.count = res.data[0].FJ.length
      }
    })
  }
  //查询当前报销单状态
  bxdStus() {
    let that = this;
    that.bxdi.getbxdS({ "bxdbh": that.bxdbh }).subscribe(res => {
      that.status = res.data
    })
  }
  upload(){
    var oInput = document.getElementById("enc");
    oInput.click();
  }
  //获取上传发票
  fileChanges(file) {
    console.log(file)
    let that = this;
    if (that.status != '审批中') {
      if (file.length === 0) {
        return;
      }
      //上传pdf
      const reader = new FileReader();
      let formData: FormData = new FormData();
      reader.readAsDataURL(file[0]) // input.files[0]为第一个文件
      reader.onload = () => {
        var a: any = reader.result;
        formData.append('file', file[0]);
        formData.append('bxd_bh', that.bxdbh);
        formData.append('pid', that.id);
        console.log(formData)
        that.pdfupload(formData)
      }
    } else {
      that.toast.error("正在审核中不可修改", '', { timeOut: 1000 })
    }
  }
  //上传pdf
  pdfupload(form) {
    let that = this;
    that.load.modalShow = true;
    that.load.text = '上传附件中...'
    that.bxdi.getpdffile(form).subscribe(res => {
      // let result = eval("(" + res + ")");
      if (res.success == 200) {
        that.getData()
        that.load.modalShow = false;


      }
    })
  }

  //删除pdf发票
  deletepdf(id) {
    let that = this;
    if (that.status != '审批中') {
      that.modal.modalShow = true;
      that.modal.text = '是否要删除'
      that.modal.btnText = ['取消', '确定']
      that.modal.yesfunc = function () {
        that.bxdi.getDelpdf({ "id": id }).subscribe(res => {
          if (res.success == 200) {
            that.toast.success(res.mes, '', { timeOut: 1000 });
            that.getData()
          }
        })
      }
    } else {
      that.toast.error("正在审核中不可修改", '', { timeOut: 1000 })
    }
  }
}
