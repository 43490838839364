import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Swiper from 'swiper'

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  @Input()
  public modalContent:any
  @Output()
  private modalContentChange:EventEmitter<any>=new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    
  }
  
  //关闭
  close() {
    this.modalContent.modalShow = false;
    this.modalContentChange.emit(this.modalContent);
    this.modalContent.canclefunc&&this.modalContent.canclefunc();
  }

  //确定
  confirm(){
    this.modalContent.modalShow = false;
    this.modalContentChange.emit(this.modalContent);
    this.modalContent.yesfunc&&this.modalContent.yesfunc();
  }

  

}
