import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {BorrowService} from '../../server/borrow.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-jkdetail',
  templateUrl: './jkdetail.component.html',
  styleUrls: ['./jkdetail.component.scss']
})
export class JkdetailComponent implements OnInit {

  public packup:any=[true,true]    //是否收起  是true，否false
  public bxdbh:string=''
  public data:any={};

    //弹出提示框内容
    public modal: any = {
      modalShow: false,
      text: '确认是否已付款',
      btnText: [],
      yesfunc: '',
      canclefunc: '',
    }

  constructor(private rou:Router,private rout:ActivatedRoute,private borr:BorrowService,private toast:ToastrService) { 

    let that=this;
    that.bxdbh=that.rout.snapshot.params['bxdbh'];
    that.payinfo();
  }

  ngOnInit(): void {
  }

  //调用付款详情页面
  payinfo(){
    let that=this;
    that.borr.getjkinfo({"jkd_bh":that.bxdbh}).subscribe(res=>{
      that.data=res.data[0]


      that.data.mx.forEach(element => {
        that.packup.push(true);
    });
    //默认第一个展开
    this.packup[0]= !this.packup[0];
    })
  }


  tuckup(index){
    this.packup[index]= !this.packup[index];
  }


    //确认付款动作
    queren(){
      let that=this;
      that.modal.modalShow=true;
      that.modal.btnText=['取消','确认']
      that.modal.yesfunc=function(){
        that.borr.getPyaEdit({"jkdbh":that.bxdbh}).subscribe(res=>{
           
            that.toast.success("操作成功",'',{timeOut:1000})

            that.payinfo();
        })
      }
     
    }

}
