import { Component, OnInit } from '@angular/core';
import {BxdService} from '../../server/bxd.service'
import * as localForage from "localforage";

@Component({
  selector: 'app-jksearch',
  templateUrl: './jksearch.component.html',
  styleUrls: ['./jksearch.component.scss']
})
export class JksearchComponent implements OnInit {
  public searchValue:string='';  //输入框值
  public history:any=[];  //历史搜索
  public id:any;   //登录人ID
  public listData:any=[];
  
  constructor(private bxd:BxdService) {
    let that=this;
    localForage.getItem("jkhistory").then(function(value:any){
      if(value !=null){
        that.history=value;
      }
    })


    localForage.getItem("user").then(function(value:any){

      that.id=value.id;
    

    })
   }

  ngOnInit(): void {
  }

  quxiao(){
    this.searchValue='';
  }

  search(e){
    let that=this;
    if(e.keyCode==13){
      if(that.history.length>=5){
        this.history.splice(0,1);
      }
      if(this.searchValue !=''){
        that.history.push('No.'+this.searchValue);

      }

      localForage.setItem("jkhistory",that.history);
      console.log(that.history);
      this.searchApi();
    }
  }

  //搜索按钮
  sear(){
    this.searchApi();
  }

  //调用搜索接口
  searchApi(){
    let that=this;
    that.bxd.getjkSearch({"id":that.id,"search":that.searchValue}).subscribe(res=>{
      that.listData=res.data;
      console.log(res);
    })
  }

  //  清除所有搜索历史
  delAll(){
    let that=this;
    that.history=[];
    localForage.removeItem("jkhistory");
  }
  //删除单个搜索记录
  del(e){
    this.history.splice(e,1);
    localForage.setItem("jkhistory",this.history);
  }

  //点击某个单号进行搜索
  diansear(e){
    let that=this;
    that.searchValue=e.substring(3);

    that.searchApi();
  }

}
