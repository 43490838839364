import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss']
})
export class LoanComponent implements OnInit {

  public numberMask: any = {
    showMask: false,
    content: [],
    checked: [],
    isSelect: []
  };
  public number:Array<string>=[];

  constructor() { }

  ngOnInit(): void {
  }

  //下拉框
  select(e) {
    
    this.numberMask.showMask = true;
      this.numberMask.content = ['No.1234568191', 'No.12345681200', 'No.12342348191'];
      if (this.numberMask.checked.length == 0) {
        for (var i = 0; i < this.numberMask.content.length; i++) {
          this.numberMask.isSelect.push(false)
        }
      }
  }

  //获取多选数据
  getData() {
    if(this.numberMask.checked.length>0){
      this.number = this.numberMask.checked;
    }else{
      this.number = [];
    }


  }

}
